import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useState, useEffect } from "react";
import { StyledDataGrid } from "../StyledDataGrid";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import theme from "../../theme";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { setBalancType } from "../../redux/slices/balanceSlice";
import { update } from "lodash";

const BalanceTransferList = (props) => {
  const dispatch = useDispatch();
  const balance = useSelector((state) => state?.balanceSlice?.balance);
  const [waterType, setWaterType] = useState("all");
  const { t } = useTranslation();
  const condominiumShow = useSelector(
    (state) => state?.condominiumSlice?.condominiumShow
  );
  const parentmeters =
    useSelector((state) => state?.condominiumSlice?.condominiumShow)
      ?.children?.map((item) => item)
      ?.map((item) => item?.children)
      .flat()
      ?.map((item) => item?.children)
      .flat()
      ?.filter((item) => item?.attributes?.serial_number) ?? [];

  const impulsiveMeters =
    useSelector((state) => state?.condominiumSlice?.condominiumShow)
      ?.children?.map((item) => item.children)
      ?.flat()
      ?.map((item) => item.children)
      ?.flat()
      ?.map((item) => item?.children)
      .flat()
      ?.filter((item) => item?.attributes?.serial_number?.includes("-")) ?? [];

  let allMeters = [...parentmeters, ...impulsiveMeters];
  const balanceType = useSelector((state) => state?.balanceSlice?.type);
  useEffect(() => {
    if (condominiumShow) {
      allMeters = [...parentmeters, ...impulsiveMeters];
    }
  }, [condominiumShow, impulsiveMeters, parentmeters]);
  const waterBalance =
    (balanceType?.[0] == "AFS" && balanceType?.[1] == "ACS") ||
    balanceType?.[0] == "ACS" ||
    balanceType?.[0] == "AFS";
  const [selectAll, setSelectAll] = useState(false);
  const [idSetted, setIdSetted] = useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [originalMeters, setOriginalMeters] = React.useState([]);
  const [checkedItems, setCheckedItems] = React.useState({});
  const [inMeters, setInMeters] = useState([]);
  const [outMeters, setOutMeters] = useState([]);
  const handleCheckboxChange = (serial, checked) => {
    const selectedMeter = allMeters?.filter(
      (item) => item?.attributes?.serial_number == serial
    );
    setCheckedItems((prev) => ({
      ...prev,
      [serial]: checked,
    }));
    if (checked) {
      setSelectedItems([...selectedItems, selectedMeter[0]]);
    } else {
      setSelectedItems(
        selectedItems?.filter(
          (item) => item?.attributes.serial_number !== serial
        )
      );
    }
  };

  const isChecked = (serial) => !!checkedItems[serial];
  React.useEffect(() => {
    if (allMeters?.length > 0) {
      setOriginalMeters(allMeters);
    }
  }, [allMeters]);

  // Состояния для фильтров
  const [subBlock, setSubBlock] = React.useState(null);
  const [apartment, setApartment] = React.useState(null);
  const [serial, setSerial] = React.useState(null);

  const filterData = (data, filters) => {
    return data?.filter((item) => {
      return Object.keys(filters).every((key) => {
        if (!filters[key]) return true;
        return item?.attributes?.[key]
          ?.toLowerCase()
          ?.includes(filters[key]?.toLowerCase());
      });
    });
  };

  const [meters, setMeters] = React.useState([]);
  /*   React.useEffect(() => {
    
  }, [originalMeters]); */

  React.useEffect(() => {
    const filteredData = filterData(originalMeters, {
      sub_condominium_name: subBlock,
      apartment_name: apartment,
      serial_number: serial,
    });
    setMeters(filteredData);
  }, [subBlock, apartment, serial, originalMeters]);

  useEffect(() => {
    if (props?.schema?.update) {
      const existsInMeters = allMeters?.filter((item) =>
        balance?.meta?.in_meters?.includes(item?.attributes?.identifier)
      );
      setInMeters([...existsInMeters]);
      const existsOutMeters = allMeters?.filter((item) =>
        balance?.meta?.out_meters?.includes(item?.attributes?.identifier)
      );
      setOutMeters([...existsOutMeters]);
      props.onChange({
        in_meters: existsInMeters?.map((item) => item?.attributes?.identifier),
        out_meters: existsOutMeters?.map(
          (item) => item?.attributes?.identifier
        ),
      });
      setIdSetted(true);
    } else {
      setIdSetted(true);
    }
  }, [props?.schema?.update]);

  const removeFromInMeters = (serial) => {
    setInMeters(
      inMeters?.filter((item) => item?.attributes?.serial_number !== serial)
    );
  };
  const removeFromOutMeters = (serial) => {
    setOutMeters(
      outMeters?.filter((item) => item?.attributes?.serial_number !== serial)
    );
  };
  React.useEffect(() => {}, [props]);
  const isItemSelected = (serial) => {
    selectedItems.findIndex((item) => item.attributes.serial_number == serial);
  };
  React.useEffect(() => {
    if (idSetted) {
      props.onChange({
        in_meters: props?.formData?.in_meters,
        out_meters: outMeters?.map((item) => item?.attributes?.identifier),
      });
    }
  }, [outMeters]);
  React.useEffect(() => {
    if (idSetted) {
      props.onChange({
        in_meters: inMeters?.map((item) => item?.attributes?.identifier),
        out_meters: props?.formData?.out_meters,
      });
    }
  }, [inMeters]);
  const columns = [
    {
      field: "subblock",
      headerName: t("subcondominum"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    /* {
      field: "room",
      headerName: t("room"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    }, */
    {
      field: "serial",
      headerName: t("serial_number"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "type",
      headerName: t("type"),
      editable: false,
      sortable: false,
      editable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      headerAlign: "right",
      flex: 1,
      renderCell: (params) => {
        if (params.value?.mode == "all") {
          const isCheckedValue = isChecked(params.row.serial);
          return (
            <Box sx={{ width: "100%" }}>
              <Checkbox
                checked={isCheckedValue}
                onChange={(event) => {
                  handleCheckboxChange(
                    params.value.value,
                    event.target.checked
                  );
                }}
              />
            </Box>
          );
        }
        if (params?.value?.mode == "in") {
          return (
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <IconButton
                onClick={() => removeFromInMeters(params?.value?.value)}
              >
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </IconButton>
            </Box>
          );
        }
        if (params?.value?.mode == "out") {
          return (
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              <IconButton
                onClick={() => removeFromOutMeters(params?.value?.value)}
              >
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </IconButton>
            </Box>
          );
        }
      },
    },
  ];

  const usedOutMeters = outMeters?.map(
    (item) => item?.attributes?.serial_number
  );
  const usedInMeters = inMeters?.map((item) => item?.attributes?.serial_number);
  const usedMeters = [...usedInMeters, ...usedOutMeters];
  React.useEffect(() => {}, [balanceType]);
  const handleSelectAll = () => {
    const filteredMeters = meters?.filter(
      (item) =>
        balanceType?.includes(item?.attributes?.meter_type) &&
        !usedMeters?.includes(item?.attributes?.serial_number)
    );

    const allChecked = filteredMeters.reduce((acc, item) => {
      acc[item.attributes.serial_number] = true;
      return acc;
    }, {});

    setCheckedItems(allChecked);

    setSelectedItems(filteredMeters);

    setSelectAll(true);
  };

  const handleUnselectAll = () => {
    setCheckedItems({});
    setSelectedItems([]);
    setSelectAll(false);
  };
  const inButtonDisable = () => {
    if (balanceType?.length == 1) {
      return selectedItems?.length > 0 ? false : true;
    }
    if (balanceType?.length == 2 && balanceType?.[0] === "Calorie") {
      return selectedItems?.length > 0 &&
        !selectedItems.some((item) => item?.attributes?.meter_type == "ACS")
        ? false
        : true;
    }
  };
  const outButtonDisable = () => {
    if (balanceType?.length == 1) {
      return selectedItems?.length > 0 ? false : true;
    }
    if (balanceType?.length == 2 && balanceType?.[0] === "Calorie") {
      return selectedItems?.length > 0 &&
        !selectedItems.some((item) => item?.attributes?.meter_type == "Calorie")
        ? false
        : true;
    }
  };

  const rows = meters
    ?.filter(
      (item) =>
        !usedMeters?.includes(item?.attributes?.serial_number) &&
        balanceType?.includes(item?.attributes?.meter_type)
    )
    ?.map((item, i) => ({
      id: i,
      subblock: item?.attributes?.sub_condominium_name,
      apartment: item?.attributes?.apartment_name,
      /*   room: item?.attributes?.room, */
      serial: item?.attributes?.serial_number,
      type: item?.attributes?.meter_type,
      actions: {
        value: item?.attributes?.serial_number,
        mode: "all",
      },
    }));
  const inMeterRows = inMeters?.map((item, i) => ({
    id: i,
    subblock: item?.attributes?.sub_condominium_name,
    apartment: item?.attributes?.apartment_name,
    /*     room: item?.attributes?.room, */
    serial: item?.attributes?.serial_number,
    type: item?.attributes?.meter_type,
    actions: {
      value: item?.attributes?.serial_number,
      mode: "in",
    },
  }));
  const outMeterRows = outMeters?.map((item, i) => ({
    id: i,
    subblock: item?.attributes?.sub_condominium_name,
    apartment: item?.attributes?.apartment_name,
    /*     room: item?.attributes?.room, */
    serial: item?.attributes?.serial_number,
    type: item?.attributes?.meter_type,
    actions: {
      value: item?.attributes?.serial_number,
      mode: "out",
    },
  }));
  const onInMetersClick = () => {
    setInMeters((prev) => [...prev, ...selectedItems]);
    setSelectedItems([]);
    setCheckedItems({});
  };
  const onOutMetersClick = () => {
    setOutMeters((prev) => [...prev, ...selectedItems]);
    setSelectedItems([]);
    setCheckedItems({});
  };
  const inButtonDisabled = () => {
    return false;
  };
  const onSelectAllClick = () => {};

  const LastColumnButton = (
    <Box sx={{ width: "100%", ml: 2 }}>
      {!selectAll && (
        <IconButton disabled={rows?.length < 1}>
          <Tooltip title={t("select_all")} onClick={handleSelectAll}>
            <SelectAllIcon />
          </Tooltip>
        </IconButton>
      )}
      {selectAll && (
        <IconButton disabled={rows?.length < 1}>
          <Tooltip title={t("deselect_all")} onClick={handleUnselectAll}>
            <DeselectIcon />
          </Tooltip>
        </IconButton>
      )}
    </Box>
  );
  const columnsWithButton = [
    ...columns,
    { renderHeader: () => LastColumnButton, sortable: false },
  ];

  useEffect(() => {
    if (rows.length < 1) {
      setSelectAll(false);
    }
  }, [rows]);
  const handleChange = (e) => {
    setWaterType(e.target.value);
    dispatch(
      setBalancType(e.target.value == "all" ? ["AFS", "ACS"] : [e.target.value])
    );
  };
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fill: theme.palette.navIcon }} />}
        >
          {t("meters")}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={!waterBalance ? 4 : 3}>
              <Autocomplete
                disablePortal
                options={
                  Array.from(
                    new Set(
                      originalMeters?.map(
                        (item) => item?.attributes?.sub_condominium_name
                      )
                    )
                  ) ?? []
                }
                onChange={(e, newValue) => {
                  setSubBlock(newValue?.label ?? newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("subcondominum")} />
                )}
              />
            </Grid>
            <Grid item xs={!waterBalance ? 4 : 3}>
              <Autocomplete
                disablePortal
                options={
                  Array.from(
                    new Set(
                      originalMeters?.map(
                        (item) => item?.attributes?.apartment_name
                      )
                    )
                  ) ?? []
                }
                onChange={(e, newValue) => {
                  setApartment(newValue?.label ?? newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("apartament")} />
                )}
              />
            </Grid>
            <Grid item xs={!waterBalance ? 4 : 3}>
              <Autocomplete
                disablePortal
                options={
                  Array.from(
                    new Set(
                      originalMeters?.map(
                        (item) => item?.attributes?.serial_number
                      )
                    )
                  ) ?? []
                }
                onChange={(e, newValue) => {
                  setSerial(newValue?.label ?? newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t("serial_number")} />
                )}
              />
            </Grid>
            {waterBalance && (
              <Grid item xs={3}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">{`${t(
                      "water_type"
                    )}`}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={waterType}
                      label={t("water_type")}
                      onChange={handleChange}
                      defaultValue=""
                    >
                      <MenuItem value={"all"}>All</MenuItem>
                      <MenuItem value={"ACS"}>ACS</MenuItem>
                      <MenuItem value={"AFS"}>AFS</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box sx={{ height: 400, width: "100%", mt: 3 }}>
            <StyledDataGrid
              disableColumnMenu
              localeText={{
                noRowsLabel: t("no_rows"),
              }}
              rows={rows}
              columns={columnsWithButton}
              disableSelectionOnClick
              getRowHeight={() => "auto"}
            />
          </Box>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
              disabled={inButtonDisable()}
              onClick={onInMetersClick}
              sx={{ mr: 1, mt: 2 }}
              variant={window.BUTTON_TYPE}
            >
              {t("set_in_meters")}
            </Button>
            <Button
              disabled={outButtonDisable()}
              onClick={onOutMetersClick}
              sx={{ mt: 2 }}
              variant={window.BUTTON_TYPE}
            >
              {t("set_out_meters")}
            </Button>
          </Box>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <Typography textAlign={"center"}>{t("in_meters")}</Typography>
              <Box sx={{ height: 400, width: "100%", mt: 3 }}>
                <StyledDataGrid
                  disableColumnMenu
                  localeText={{
                    noRowsLabel: t("no_rows"),
                  }}
                  rows={inMeterRows ?? []}
                  columns={columns}
                  disableSelectionOnClick
                  getRowHeight={() => "auto"}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography textAlign={"center"}>{t("out_meters")}</Typography>
              <Box sx={{ height: 400, width: "100%", mt: 3 }}>
                <StyledDataGrid
                  localeText={{
                    noRowsLabel: t("no_rows"),
                  }}
                  disableColumnMenu
                  rows={outMeterRows ?? []}
                  columns={columns}
                  disableSelectionOnClick
                  getRowHeight={() => "auto"}
                />
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BalanceTransferList;
