import { Box, IconButton, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import OpenValve from "./Icons/OpenValve";
import PolicyShowCard from "./PolicyShowCard";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseValveIcon from "./Icons/CloseValveIcon";
import OpeningValveIcon from "./Icons/OpeningValveIcon";
import ClosingValveIcon from "./Icons/ClosingValveIcon";
import OkStatusIcon from "./Icons/OkStatusIcon";
import BypassOpenIcon from "./Icons/BypassOpenIcon";
import BypassCloseIocn from "./Icons/BypassCloseIocn";
import OverConsumIcon from "./Icons/OverConsumIcon";
import OverMaxPeriodIcon from "./Icons/OverMaxPeriodIcon";
import AnomalyIcon from "./Icons/AnomalyIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import theme from "../../theme";
import { Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import InternalValve from "./Icons/InternalValve";
import ExternalValve from "./Icons/ExternalValve";
import ModalDialog from "../meters/ModalDialog";
import { setMessage } from "../../redux/slices/snackbarSlice";
import AddDeviceTable from "./supply_widgets/AddDeviceTable";
import DeleteButton from "../../utils/DeleteButton";
const PolicyShow = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const policyShow = useSelector((state) => state?.policySlice.policyShow);
  const [trashId, setTrashId] = useState(null);
  const [deleteDevice, { isSuccess: deleteSuccess }] =
    store.useDeleteDeviceFromPolicyMutation();
  const [getDevicePolicy] = store.useLazyGetDevicePolicyIndexQuery();
  const [deviceModal, setDeviceModal] = useState(false);
  const devicePolicyIndex = useSelector(
    (state) => state?.policySlice?.devicePolicyIndex
  );
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [getPolicyShow] = store.useLazyGetPolicyShowQuery();
  const perPage = 10;
  const { t } = useTranslation();
  const now = new Date();
  const startDate = new Date(policyShow?.start_date);
  const endDate = new Date(policyShow?.end_date);
  const isActive = now >= startDate && now <= endDate;
  const isPassed = now > endDate;

  useEffect(() => {
    if (id) {
      getPolicyShow(id);
      getDevicePolicy({ policyId: id, params: {} });
    }
  }, [id]);

  useEffect(() => {
    console.log("plshw", policyShow);
  }, [policyShow]);

  useEffect(() => {
    if (policyShow) {
      dispatch(
        setBreadCrumbs({
          id: policyShow?.name,
        })
      );
      return () => {
        dispatch(setBreadCrumbs(null));
      };
    }
  }, [policyShow]);

  const valveStateIconSelector = (state) => {
    switch (state) {
      case "open":
        return (
          <Tooltip title={t(`${state}`)}>
            <Box>
              <OpenValve color={theme.palette.success.main} />
            </Box>
          </Tooltip>
        );
      case "close":
        return (
          <Tooltip title={t(`${state}`)}>
            <Box>
              <CloseValveIcon color={theme.palette.error.main} />
            </Box>
          </Tooltip>
        );
      case "opening":
        return (
          <Tooltip title={t(`${state}`)}>
            <Box>
              <OpeningValveIcon color={theme.palette.error.main} />
            </Box>
          </Tooltip>
        );
      case "closing":
        return (
          <Tooltip title={t(`${state}`)}>
            <Box>
              <ClosingValveIcon color={theme.palette.error.main} />
            </Box>
          </Tooltip>
        );
      default:
        return "--";
    }
  };
  const replaceUnderscoresWithSpaces = (str) => str.replace(/_/g, " ");

  const statusIconSelector = (state) => {
    switch (state) {
      case "ok":
        return (
          <Tooltip title={t(`${replaceUnderscoresWithSpaces(state)}`)}>
            <Box>
              <OkStatusIcon />
            </Box>
          </Tooltip>
        );
      case "consumption_threshold_exceeded":
        return (
          <Tooltip title={t(`${replaceUnderscoresWithSpaces(state)}`)}>
            <Box>
              <OverConsumIcon />
            </Box>
          </Tooltip>
        );
      case "anomaly":
        return (
          <Tooltip title={t(`${replaceUnderscoresWithSpaces(state)}`)}>
            <Box>
              <AnomalyIcon />
            </Box>
          </Tooltip>
        );
      case "bypass_open":
        return (
          <Tooltip title={t(`${replaceUnderscoresWithSpaces(state)}`)}>
            <Box>
              <OkStatusIcon />
            </Box>
          </Tooltip>
        );
      case "bypass_close":
        return (
          <Tooltip title={t(`${replaceUnderscoresWithSpaces(state)}`)}>
            <Box>
              <BypassCloseIocn />
            </Box>
          </Tooltip>
        );
      case "over_max_period":
        return (
          <Tooltip title={t(`${replaceUnderscoresWithSpaces(state)}`)}>
            <Box>
              <OverMaxPeriodIcon />
            </Box>
          </Tooltip>
        );
      default:
        return "NA";
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      editable: false,
      flex: 1,
      renderCell: (params) => {
        if (params?.value?.state == "init") {
          return <Typography>{params?.value?.serial}</Typography>;
        } else if (params?.value?.state !== "init" && params?.value?.id) {
          return (
            <Box display={"flex"} width={"100%"} alignItems={"center"}>
              <Typography>{params?.value?.serial}</Typography>
              <Tooltip title={t("external_valve")}>
                <Box sx={{ ml: 2 }}>
                  <ExternalValve />
                </Box>
              </Tooltip>
            </Box>
          );
        } else if (params?.value?.state !== "init" && !params?.value?.id) {
          return (
            <Box display={"flex"} width={"100%"} alignItems={"center"}>
              <Typography>{params?.value?.serial}</Typography>
              <Tooltip title={t("internal_valve")}>
                <Box sx={{ ml: 2 }}>
                  <InternalValve />
                </Box>
              </Tooltip>
            </Box>
          );
        }
      },
    },
    {
      field: "state",
      headerName: t("state"),
      width: 90,
      sortable: false,

      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-start"}>
            {statusIconSelector(params?.value)}
          </Box>
        );
      },
    },
    {
      field: "valve_state",
      headerName: t("valve_state"),
      width: 90,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-start"}>
            {valveStateIconSelector(params?.value)}
          </Box>
        );
      },
    },
    {
      field: "inserted_at",
      headerName: t("inserted_at"),
      width: 90,
      sortable: false,

      flex: 1,
    },
    {
      field: "updated_at",
      headerName: t("updated_at"),
      width: 90,
      sortable: false,

      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Link to={`/supply_policies/${id}/device/${params.value}`}>
              <VisibilityIcon sx={{ mt: 1, fill: theme.palette.navIcon }} />
            </Link>
            {!isActive && !isPassed && (
              <Tooltip title={t("delete_device_from_policy")}>
                <IconButton onClick={() => openModal(params.value)}>
                  <DeleteIcon sx={{ fill: theme.palette.error.main }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
  ];
  const rows = devicePolicyIndex?.map((item) => ({
    id: item?.id,
    serial_number: {
      serial: item?.serial_number,
      id: item?.compound_device_id,
      state: item?.state,
    },
    state: item?.state,
    valve_state: item?.valve_state,
    inserted_at: t("dt", {
      val: new Date(item?.inserted_at),
    }),
    updated_at: t("dt", {
      val: new Date(item?.updated_at),
    }),
    actions: item?.id,
  }));

  const openModal = (id) => {
    setModal(true);
    setTrashId(id);
  };
  const closeModal = () => {
    setModal(false);
    setTrashId(null);
  };

  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      closeModal();
      getDevicePolicy({ policyId: id, params: {} });
    }
  }, [deleteSuccess]);

  const onDeleteClick = () => {
    deleteDevice({
      policyId: id,
      deviceId: trashId,
    });
  };

  const openDeviceModal = () => {
    setDeviceModal(true);
  };
  const closeDeviceModal = () => {
    setDeviceModal(false);
  };

  return (
    <>
      <ModalDialog
        open={deviceModal}
        title={t("add_device")}
        close={closeDeviceModal}
      >
        <AddDeviceTable />
      </ModalDialog>
      <ModalDialog
        open={modal}
        title={t("delete_device_from_policy")}
        close={closeModal}
      >
        <Box>
          <Typography>{t("delete_device_from_policy_text")}</Typography>
          <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"space-between"}>
            <DeleteButton name={t("yes")} onClick={onDeleteClick} />
            <Button onClick={closeModal}>{t("no")}</Button>
          </Box>
        </Box>
      </ModalDialog>
      <PolicyShowCard data={policyShow} />
      {!isActive && !isPassed && (
        <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
          <Button onClick={openDeviceModal}>{t("add_device")}</Button>
        </Box>
      )}
      <Box sx={{ mb: 3, mt: 2 }}>
        <IwdDataGrid
          columns={columns}
          perPage={devicePolicyIndex?.length}
          setPage={setPage}
          rows={rows}
          page={page}
          count={devicePolicyIndex?.length}
          paginationMode={"client"}
          sort={false}
          height={545}
          /*   styles={styleSelector} */
          tableName={"supply_policy_index"}
        />
      </Box>
    </>
  );
};
export default PolicyShow;
