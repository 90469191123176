import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
const StatisticAutocomolete = (props) => {
  const updating = useSelector((state) => state?.widgetSlice?.updating);
  const [firstCall, setFirstCall] = useState(true);
  const [getStatistics] = store.useLazyGetStatisticsQuery();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState("");
  const [searchValue, setSearchValue] = useState(null);
  const data = props?.schema?.data;
  useEffect(() => {
    if (value) {
      props.onChange(value.value);
    }
  }, [value]);
  useEffect(() => {
    console.log("propsstat", props);
    if (props?.formData && data.length > 0) {
      const filteredData = data?.filter(
        (item) => item.value === props?.formData
      );
      console.log("propsstatfd", filteredData);
      setValue(filteredData[0]);
    }
  }, [props?.formData, data]);
  const debValue = debounce((e) => {
    console.log("debik");
    setSearchValue(e.target.value);
  }, 500);

  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      getStatistics({ name: searchValue }).then(() => {
        setLoading(false);
        /*  setSearchValue(null); */
      });
    }
  }, [searchValue]);

  const getDefaultStatistics = () => {
    if (props.schema.update && firstCall) {
      getStatistics();
      setFirstCall(false);
    }
  };

  useEffect(() => {
    console.log("searchValue", searchValue);
  }, [searchValue]);
  return (
    <>
      <Autocomplete
        disablePortal
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        id="combo-box-demo"
        options={data}
        renderInput={(params) => {
          return (
            <TextField
              onChange={debValue}
              onClick={getDefaultStatistics}
              {...params}
              value={value}
              error={props?.rawErrors?.length > 0}
              required={props.required}
              label={props.schema.title}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CircularProgress color="inherit" size={20} />}
                  </InputAdornment>
                ),
              }}
            />
          );
        }}
      />
    </>
  );
};
export default StatisticAutocomolete;
