import Tooltip from "@mui/material/Tooltip";
import PopoverDashboardCardError from "../Popover";
import {
  selectColor,
  selectTextColor,
  selectLabel,
} from "./utils/widgetConfigurations";
import Box from "@mui/material/Box";
import { Grid, Typography, useTheme, Paper, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Chip, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import PopoverCardTitle from "../PopoverCardTitle";
import { store } from "../../../redux/store";
import { deleteCreatedWidget } from "../../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { setDragBlcok } from "../../../redux/slices/dashboardSlice";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";

const SingleValuewidget = ({
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase,
}) => {
  const dispatch = useDispatch();
  const previewMode = useSelector((state) => state?.widgetsSlice?.previewMode);
  const [linkUnderline, setLinkUnderline] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const dashboardId = useSelector(
    (state) => state?.dashboardSlice?.dashboardId
  );
  const [
    getDashboardShow,
    { isSuccess: dashSuccess, error: dashShowErr, isFetching: showFetch },
  ] = store.useLazyGetDashboardShowQuery();
  const onTrashClick = (id, event) => {
    console.log("trwsdasd");
    event.stopPropagation();
    event.preventDefault();
    setTrashModal(true);
    setTrashId(id);
  };

  const onDelete = (id, event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(deleteCreatedWidget(id));
  };

  const value = previewMode
    ? 0
    : widget?.last_update?.data?.[0][
        Object.keys(widget?.last_update?.data[0])?.[0]
      ];

  const calculateFontSize = (value) => {
    const length = value.toString().length;
    if (length < 3) return "3rem"; // Если 1-2 цифры
    if (length < 5) return "3rem"; // Если 3-4 цифры
    if (length < 7) return "3rem"; // Если 5-6 цифр
    return "2rem"; // Если 7 и более цифр
  };

  const fontSize = calculateFontSize(value);

  return (
    <Paper
      elevation={5}
      sx={{
        width: "100%",
        height: 390,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        zIndex: 9999,
      }}
    >
      <Box sx={{ mt: 1 }}>
        <PopoverCardTitle
          title={widget?.name}
          description={widget?.description}
          edit={edit}
        />
        <Divider />
        {edit && (
          <IconButton sx={{ float: "right" }}>
            <DeleteIcon
              onMouseEnter={() => dispatch(setDragBlcok(true))}
              onMouseLeave={() => dispatch(setDragBlcok(false))}
              onClick={(event) =>
                creationPhase
                  ? onDelete(widget?.id, event)
                  : onTrashClick(widget?.id, event)
              }
              sx={{
                float: "right",
                fill: theme?.palette.error.main,
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}
        {/* {!edit && (
          <Tooltip title={t("update_widget")}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => getDashboardShow(dashboardId)}
            >
              <ReplayIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: fontSize, // Устанавливаем динамический размер шрифта
            lineHeight: 1.2, // Корректируем межстрочный интервал для лучшего расположения
            overflow: "hidden", // Предотвращаем переполнение
            textOverflow: "ellipsis", // Используем многоточие, если текст слишком длинный
            whiteSpace: "nowrap", // Не переносим текст на новую строку
          }}
        >
          {widget.link && (
            <a
              onMouseEnter={() => setLinkUnderline(true)}
              onMouseLeave={() => setLinkUnderline(false)}
              style={{
                textDecoration: linkUnderline ? "" : "none",
                color: "inherit",
              }}
              href={widget?.link}
              target="_blank"
            >
              {value}
            </a>
          )}
          {!widget.link && value}
        </Typography>

        {widget?.last_error?.date && !previewMode && (
          <PopoverDashboardCardError error={widget?.last_error} />
        )}
      </Box>
      <Box sx={{ m: 1 }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={1}
          direction="row"
          spacing={1}
          xs={12}
        >
          {widget?.link ? (
            <>
              <Grid item xs={4}>
                {widget?.tags?.map((item) => (
                  <Chip
                    style={{
                      background: theme.palette.chip,
                    }}
                    label={selectLabel(widget?.tags?.[0], theme)}
                  />
                ))}
              </Grid>
            </>
          ) : (
            <Grid item xs={4}>
              {widget?.tags?.map((item) => (
                <Chip
                  style={{
                    background: theme.palette.chip,
                  }}
                  label={selectLabel(widget?.tags?.[0], theme)}
                />
              ))}
            </Grid>
          )}

          <Grid item xs={8}>
            <Tooltip title={t("last_update")}>
              <Typography
                textAlign="end"
                variant="body2"
                sx={{ fontSize: "0.75rem" }}
              >
                {t("dt", { val: new Date(widget?.last_update?.date) })}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SingleValuewidget;
