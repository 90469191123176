import * as React from "react";

const OngoingPolicyIcon = ({
  color = "#4CAF50",
  borderColor = "#000",
  borderWidth = 1,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icon"
    width="16"
    height="16"
    fill="#2e7d32"
    stroke="#2e7d32"
    viewBox="0 0 32 32"
  >
    <g id="SVGRepo_iconCarrier">
      <path d="M16 2a14 14 0 1 0 14 14A14.016 14.016 0 0 0 16 2Zm0 26a12 12 0 0 1 0-24v12l8.481 8.481A11.96 11.96 0 0 1 16 28Z"></path>
    </g>
  </svg>
);

export default OngoingPolicyIcon;
