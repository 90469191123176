import { store } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
const DeviceAutocomleteWidget = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [getMeters] = store.useLazyGetMeterIndexQuery();
  const metersIndex = useSelector((state) => state?.meterSlice?.metersIndex);

  const options = metersIndex?.map((item) => ({
    label: item?.attributes?.serial_number,
    value: item?.id,
  }));
  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);
  useEffect(() => {
    if (!metersIndex) {
      getMeters({ page: 1, per_page: 12 });
    }
  }, [metersIndex]);

  useEffect(() => {
    if (searchValue) {
      getMeters({ serial_number: searchValue });
    }
  }, [searchValue]);

  useEffect(() => {
    if (value) {
      props.onChange(value.value);
    }
  }, [value]);

  useEffect(() => {
    if (value == null) {
      props.onChange("");
      getMeters({ page: 1, per_page: 12 });
    }
    console.log("valyambaaa", value);
  }, [value]);

  return (
    <Autocomplete
      fullWidth
      disablePortal
      value={value}
      id="combo-box-demo"
      options={options ?? []}
      onChange={(event, newInputValue) => {
        setValue(newInputValue);
      }}
      onInputChange={(e, newInputValue) => {
        debValue(e);
      }}
      renderInput={(params) => (
        <TextField {...params} label={t("serial_number")} />
      )}
    />
  );
};
export default DeviceAutocomleteWidget;
