import { store } from "../../../redux/store";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DevicePolicyShowCard from "./DevicePolicyShowCard";
import DevicrPolicyEvents from "./DevicrPolicyEvents";
import { setBreadCrumbs } from "../../../redux/slices/breadCrumbMeterSlice";

const DevicePolicyShow = () => {
  const policyShow = useSelector((state) => state?.policySlice.policyShow);
  const devicePolicyShow = useSelector(
    (state) => state?.policySlice?.devicePolicyShow
  );
  const dispatch = useDispatch();
  const { id, deviceId } = useParams();
  const [getDevicePolicyShow] = store.useLazyGetDevicePolicyShowQuery();
  const [page, setPage] = useState(1);
  const per_page = 10;

  useEffect(() => {
    if (id && deviceId) {
      getDevicePolicyShow({ policyId: id, devicePolicyId: deviceId });
    }
  }, [id, deviceId]);
  useEffect(() => {
    if (policyShow && devicePolicyShow) {
      dispatch(
        setBreadCrumbs({
          id: policyShow?.name,
          deviceId: devicePolicyShow?.serial_number,
        })
      );
      return () => {
        dispatch(setBreadCrumbs(null));
      };
    }
  }, [policyShow, devicePolicyShow]);

  const params = {
    page,
    per_page,
  };

  return (
    <>
      <DevicePolicyShowCard params={params} />
      <DevicrPolicyEvents page={page} setPage={setPage} per_page={per_page} />
    </>
  );
};
export default DevicePolicyShow;
