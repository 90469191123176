import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/it";

const MeterIndexDatePicker = (props) => {
  const defaultStartDate = props?.schema?.properties?.from?.default;
  const defaultEndDate = props?.schema?.properties?.to?.default;
  const [startDate, setStartDate] = useState(
    defaultStartDate ? defaultStartDate : null
  );
  const [endDate, setEndDate] = useState(
    defaultEndDate ? defaultEndDate : null
  );
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const StartDateErrorMessage = props?.errorSchema?.from?.__errors[0];
  const EndDateErrorMessage = props?.errorSchema?.to?.__errors[0];

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);

    if (newValue && newValue !== "Invalid Date") {
      const start = new Date(newValue);
      const from = new Date(
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
      );
      props.onChange({ from: from.toISOString(), to: props.formData?.to });
    } else {
      props.onChange({ from: null, to: props.formData?.to });
    }
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue);

    if (newValue && newValue !== "Invalid Date") {
      const end = new Date(newValue);
      const to = new Date(
        Date.UTC(end.getFullYear(), end.getMonth(), end.getDate())
      );
      props.onChange({ from: props.formData?.from, to: to.toISOString() });
    } else {
      props.onChange({ from: props.formData?.from, to: null });
    }
  };

  useEffect(() => {
    if (props?.schema?.refreshData) {
      setEndDate(null);
      setStartDate(null);
      props?.schema?.setRefreshData(false);
    }
  }, [props?.schema?.refreshData]);

  return (
    <Grid container>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage.split("-")[0]}
      >
        <Grid item xs={6}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%" }}
                {...props}
                error={!!StartDateErrorMessage}
                helperText={StartDateErrorMessage}
              />
            )}
            label={t("start_insertion_date")}
            value={startDate}
            onChange={handleStartDateChange}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%", ml: 1 }}
                {...props}
                error={!!EndDateErrorMessage}
                helperText={EndDateErrorMessage}
              />
            )}
            label={t("end_insertion_date")}
            value={endDate}
            onChange={handleEndDateChange}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default MeterIndexDatePicker;
