import BsLogo from "../components/svgs/BsLogo";
import LogoMad from "../components/svgs/LogoMad";
import IntegraLogo from "../components/svgs/IntegraLogo";

export const selectLogo = () => {
  switch (window.CLIENT) {
    case "bs":
      return <BsLogo />;
    case "mad":
      return <LogoMad />;
    case "integra":
      return <IntegraLogo />;
  }
};
