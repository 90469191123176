import * as React from "react";
import { Tooltip } from "@mui/material";
const OverConsumIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 16 16"
  >
    <path
      fill="#2e3434"
      d="M8 1.004c-2.05 0-4.098.781-5.656 2.34-3.117 3.117-3.117 8.195 0 11.312a1 1 0 1 0 1.414-1.414 5.984 5.984 0 0 1 0-8.484 5.984 5.984 0 0 1 8.484 0 5.98 5.98 0 0 1 0 8.484 1 1 0 1 0 1.414 1.414c3.117-3.117 3.117-8.195 0-11.312A7.97 7.97 0 0 0 8 1.004m5.195 11-.363-1.363L10 9.004c0-.715-.383-1.371-1-1.73a2.002 2.002 0 0 0-2 3.464 2 2 0 0 0 1.996-.004l2.836 1.637zm0 0"
    ></path>
    <path
      fill="#2e3436"
      fillOpacity="0.349"
      d="M12 9a4 4 0 0 1-1.18 2.82l2.13 2.13A7 7 0 0 0 15 9zm0 0"
    ></path>
  </svg>
);

export default OverConsumIcon;
