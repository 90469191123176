import * as React from "react";
import { Tooltip } from "@mui/material";
const AnomalyIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 512 512"
  >
    <g id="Page-1" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        id="Shape"
        fill="#000"
        d="M170.667 0c94.104 0 170.666 76.563 170.666 170.667 0 39.373-13.534 75.573-36.018 104.481L426.63 396.464l-30.166 30.166-121.316-121.315c-28.908 22.484-65.108 36.018-104.481 36.018C76.563 341.333 0 264.771 0 170.667S76.563 0 170.667 0m0 148.928L133.408 240l-70.296.001c22.817 35.275 62.504 58.666 107.555 58.666 45.05 0 84.737-23.39 107.555-58.666L207.925 240zm0-106.261c-70.584 0-128 57.416-128 128 0 12.982 1.942 25.518 5.551 37.334l63.697-.001 58.752-143.59L229.397 208l63.718.001c3.61-11.816 5.552-24.352 5.552-37.334 0-70.584-57.417-128-128-128"
        transform="translate(42.667 42.667)"
      ></path>
    </g>
  </svg>
);

export default AnomalyIcon;
