import { prepareBody } from "../../utils/api_params";
import { prepareParams } from "../../utils/api_params";
import { prepareBodyWithoutId } from "../../utils/api_params";

export const policyApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getPolicyIndex: build.query({
        query: (params) => {
          return `v1/plugin/policy?${prepareParams(params)}`;
        },
      }),
      getPolicyShow: build.query({
        query: (id) => {
          return `v1/plugin/policy/${id}`;
        },
      }),
      createPolicy: build.mutation({
        query: (attributes) => ({
          url: `v1/plugin/policy`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      updatePolicy: build.mutation({
        query: ({ id, attributes }) => ({
          url: `v1/plugin/policy/${id}`,
          method: "PUT",
          body: JSON.stringify(prepareBody(attributes, id, "plugin")),
        }),
      }),
      deletePolicy: build.mutation({
        query: (id) => ({
          url: `v1/plugin/policy/${id}`,
          method: "DELETE",
        }),
      }),
      getDevicePolicyIndex: build.query({
        query: ({ policyId, params }) => {
          return `v1/plugin/policy/${policyId}/device_policy?${prepareParams(
            params
          )}`;
        },
      }),
      getDevicePolicyShow: build.query({
        query: ({ policyId, devicePolicyId }) => {
          return `v1/plugin/policy/${policyId}/device_policy/${devicePolicyId}`;
        },
      }),
      getDevicePolicyEvents: build.query({
        query: ({ policyId, devicePolicyId, params }) => {
          return `v1/plugin/policy/${policyId}/device_policy/${devicePolicyId}/events?${prepareParams(
            params
          )}`;
        },
      }),
      openValve: build.mutation({
        query: ({ policyId, devicePolicyId, attributes }) => ({
          url: `v1/plugin/policy/${policyId}/device_policy/${devicePolicyId}/bypass_open`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      closeValve: build.mutation({
        query: ({ policyId, devicePolicyId, attributes }) => ({
          url: `v1/plugin/policy/${policyId}/device_policy/${devicePolicyId}/bypass_close`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      exitBypass: build.mutation({
        query: ({ policyId, devicePolicyId, attributes }) => ({
          url: `v1/plugin/policy/${policyId}/device_policy/${devicePolicyId}/exit_from_bypass`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      abortPolicy: build.mutation({
        query: (id) => ({
          url: `/v1/plugin/policy/${id}/abort`,
          method: "DELETE",
        }),
      }),
      addDeviceToPolicy: build.mutation({
        query: ({ policyId, attributes }) => ({
          url: `v1/plugin/policy/${policyId}/device_policy`,
          method: "POST",
          body: JSON.stringify(prepareBodyWithoutId(attributes, "plugin")),
        }),
      }),
      deleteDeviceFromPolicy: build.mutation({
        query: ({ policyId, deviceId }) => ({
          url: `v1/plugin/policy/${policyId}/device_policy/${deviceId}`,
          method: "DELETE",
        }),
      }),
    }),
  });
