import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../redux/store";
import { api } from "../redux/slices/api";

const HelmetHeader = () => {
  const favid = useSelector((state) => state?.configurationSlice?.favId);
  const bearer = localStorage.getItem("user_token");

  const dispatch = useDispatch();

  const selectManifest = () => {
    switch (window.CLIENT) {
      case "bs":
        return "/manifest.json";
      case "mad":
        return "/manifest_mad.json";
    }
  };

  const test = window.TITLE;
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{test}</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        {favid && (
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${window.BASE_URL}/api/v1/image/${favid}?token=${bearer}`}
          />
        )}
        {favid && (
          <link
            rel="icon"
            type="image/png"
            sizes="96x96"
            href={`${window.BASE_URL}/api/v1/image/${favid}?token=${bearer}`}
          />
        )}
        {favid && (
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${window.BASE_URL}/api/v1/image/${favid}?token=${bearer}`}
          />
        )}
        <link rel="manifest" href={selectManifest()}></link>
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content="Altior water resource manager" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <script
          src="https://unpkg.com/react/umd/react.production.min.js"
          crossorigin
        ></script>

        <script
          src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
          crossorigin
        ></script>

        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto"
        />
        <script>var Alert = ReactBootstrap.Alert;</script>

        <script
          src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"
          integrity="sha512-XQoYMqMTK8LvdxXYG3nZ448hOEQiglfqkJs1NOQV44cWnUrBc8PkAOcXy20w0vlaXaVUearIOBhiXZ5V3ynxwA=="
          crossorigin=""
        ></script>
      </Helmet>
    </div>
  );
};
export default HelmetHeader;
// import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
// import { useSelector } from "react-redux";

// const HelmetHeader = () => {
//   const [manifest, setManifest] = useState(null);
//   const favid = useSelector((state) => state?.configurationSlice?.favId);
//   const bearer = localStorage.getItem("user_token");

//   const manifestSelector = () => {
//     switch (window.CLIENT) {
//       case "bs":
//         return {
//           short_name: "React App",
//           name: "Create React App Sample",
//           icons: [
//             {
//               src: "favicon.ico",
//               sizes: "64x64 32x32 24x24 16x16",
//               type: "image/x-icon",
//             },
//             { src: "logo192.png", type: "image/png", sizes: "192x192" },
//             { src: "logo512.png", type: "image/png", sizes: "512x512" },
//           ],
//           start_url: ".",
//           display: "standalone",
//           theme_color: "#000000",
//           background_color: "#ffffff",
//         };
//       case "mad":
//         return {
//           short_name: "testtt",
//           name: "test",
//           icons: [
//             {
//               src: "arqFav.png",
//               sizes: "64x64 32x32 24x24 16x16",
//               type: "image/x-icon",
//             },
//             {
//               src: "arqFav.png",
//               type: "image/png",
//               sizes: "192x192",
//             },
//             {
//               src: "arqFav.png",
//               type: "image/png",
//               sizes: "512x512",
//             },
//           ],
//           start_url: ".",
//           display: "standalone",
//           theme_color: "#000000",
//           background_color: "#ffffff",
//         };

//       default:
//         return {
//           short_name: "Default App",
//           name: "Default Application",
//           icons: [
//             {
//               src: "/public/favicon.ico",
//               sizes: "64x64 32x32 24x24 16x16",
//               type: "image/x-icon",
//             },
//             { src: "logo192.png", type: "image/png", sizes: "192x192" },
//             { src: "logo512.png", type: "image/png", sizes: "512x512" },
//           ],
//           start_url: ".",
//           display: "standalone",
//           theme_color: "#000000",
//           background_color: "#ffffff",
//         };
//     }
//   };

//   useEffect(() => {
//     const selectedManifest = manifestSelector();
//     setManifest(selectedManifest);
//   }, []);

//   if (!manifest) return null;

//   return (
//     <div>
//       <Helmet>
//         <html lang="en" />
//         <title>{manifest.name}</title>

//         {/* Dynamic favicon links */}
//         {favid && (
//           <>
//             <link
//               rel="icon"
//               type="image/png"
//               sizes="32x32"
//               href={`${window.BASE_URL}/api/v1/image/${favid}?token=${bearer}`}
//             />
//             <link
//               rel="icon"
//               type="image/png"
//               sizes="96x96"
//               href={`${window.BASE_URL}/api/v1/image/${favid}?token=${bearer}`}
//             />
//             <link
//               rel="icon"
//               type="image/png"
//               sizes="16x16"
//               href={`${window.BASE_URL}/api/v1/image/${favid}?token=${bearer}`}
//             />
//           </>
//         )}

//         <link rel="manifest" href={window.BASE_URL + "/manifest.json"} />
//         <meta name="theme-color" content={manifest.theme_color} />
//         <meta name="description" content={manifest.name} />

//         {/* Other meta tags */}
//         <meta
//           name="msapplication-TileColor"
//           content={manifest.background_color}
//         />
//         <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />

//         <link
//           rel="apple-touch-icon"
//           sizes="192x192"
//           href="/android-icon-192x192.png"
//         />

//         {manifest?.icons?.map((icon, index) => (
//           <link
//             key={index}
//             rel="icon"
//             type={icon.type}
//             sizes={icon.sizes}
//             href={icon.src}
//           />
//         ))}
//       </Helmet>
//     </div>
//   );
// };

// export default HelmetHeader;
