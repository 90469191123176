import { Box, Typography, Button } from "@mui/material";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import MeterDatePicker from "../form/formComponents/MeterDatePicker";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMessage } from "../../redux/slices/snackbarSlice";
import PolicyTranser from "./PolicyTranser";
import BalanceTransferList from "../../pages/balances/BalanceTransferList";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import UserWidget from "../users/UserWidget";
import Threshold from "./supply_widgets/Threshold";
import { store } from "../../redux/store";
import { useParams } from "react-router-dom";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import ClosePercentage from "./supply_widgets/ClosePercentage";
import SupplyUserWidget from "./supply_widgets/SupplyUserWidget";
import RolesWidget from "./supply_widgets/RolesWidget";
const fullRowNames = [
  "description",
  "device_ids",
  "insertion_date",
  "automatic_closing",
];

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (fullRowNames?.includes(element.name)) {
          return (
            <Grid key={element.id} size={12}>
              {element.content}
            </Grid>
          );
        }

        return (
          <Grid key={element.id} alignItems="center" size={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const refresh_rate_enum = [
  { const: 1 },
  { const: 2 },
  { const: 3 },
  { const: 4 },
];
const PolicyUpdate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const policyShow = useSelector((state) => state?.policySlice?.policyShow);
  const [updatePolicy, { isSuccess }] = store.useUpdatePolicyMutation();
  const { id } = useParams();
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
        default: policyShow?.name,
      },
      user_id: {
        type: "string",
        title: t("user"),
        default: policyShow?.user_id,
        policyUser: true,
      },
      meter_refresh_rate: {
        type: "integer",
        title: t("meter_refresh_rate"),
        oneOf: [...refresh_rate_enum],
        default: policyShow?.meter_refresh_rate,
      },
      close_percentage: {
        type: "integer",
        title: t("close_percentage"),
        default: policyShow?.close_percentage,
      },
      /*   max_period: {
        type: "integer",
        title: t("max_period"),
        oneOf: [...max_period_enum],
      }, */
      consumption_threshold: {
        type: "integer",
        title: t("consumption_treshhold"),
        default: policyShow?.consumption_threshold,
      },
      roles: {
        type: "array",
        title: t("roles"),
        roles: true,
        multiple: true,
        url: "role",
        default: policyShow?.roles,
      },
      automatic_closing: {
        type: "string",
        title: t("automatic_closing"),
        enum: ["true", "false"],
        enumNames: [t("yes"), t("no")],
        default: policyShow?.automatic_closing?.toString(),
      },
      /*  applies_to_holidays: {
        type: "string",
        title: t("applies_to_holidays"),
        enum: ["true", "false"],
        enumNames: [t("yes"), t("no")],
        default: "false",
        default: policyShow?.applies_to_holidays?.toString(),
      }, */
      insertion_date: {
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
            default: policyShow?.start_date,
          },
          to: {
            type: "string",
            default: policyShow?.end_date,
          },
        },
      },

      description: {
        type: "string",
        title: t("description"),
        default: policyShow?.description ?? "",
      },
    },
    required: [
      "name",
      "user_id",
      "meter_refresh_rate",
      "close_percentage",
      "consumption_threshold",
      "roles",
      "automatic_closing",
    ],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
    interrupted: {
      "ui:field": "SingleDatePicker",
    },
    description: {
      "ui:widget": "textarea",
    },
    roles: {
      "ui:field": "roles",
    },

    close_percentage: {
      "ui:field": "ClosePercentage",
    },
    consumption_threshold: {
      "ui:field": "Threshold",
    },
    user_id: {
      "ui:field": "user",
    },
    "ui:submitButtonOptions": {
      submitText: t("update"),

      props: {
        className: "bntListSearch",
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };
  const fields = {
    datepicker: MeterDatePicker,
    SingleDatePicker: SingleDatePicker,
    roles: RolesWidget,

    user: SupplyUserWidget,
    ClosePercentage: ClosePercentage,
    Threshold: Threshold,
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("update_success")));
      navigate(`/supply_policies/${id}`);
    }
  }, [isSuccess]);

  const onSubmitClick = () => {
    const attributes = {
      name: formData?.name,
      start_date: formData?.insertion_date?.from,
      end_date: formData?.insertion_date?.to,
      user_id: formData?.user_id,
      meter_refresh_rate: formData?.meter_refresh_rate,
      close_percentage: formData?.close_percentage,
      automatic_closing: formData?.automatic_closing === "true" ? true : false,
      max_period: formData?.max_period,
      consumption_threshold: formData?.consumption_threshold,
      roles: formData?.roles,
      description: formData?.description,
      max_period: 5,
    };
    updatePolicy({ id: id, attributes: attributes });
  };

  useEffect(() => {
    console.log("updfmdtplc", formData);
  }, [formData]);

  useEffect(() => {
    if (policyShow) {
      dispatch(
        setBreadCrumbs({
          id: policyShow?.name,
        })
      );
      return () => {
        dispatch(setBreadCrumbs(null));
      };
    }
  }, [policyShow]);

  return (
    <Box>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={onSubmitClick}
        validator={validator}
        uiSchema={uiSchema}
        /*  customValidate={customValidate} */
        showErrorList={false}
        noHtml5Validate
      />
    </Box>
  );
};
export default PolicyUpdate;
