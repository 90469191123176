import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Alert,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { store } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import IwdDataGrid from "../../../pages/IwdDataGrid";
import AddIcon from "@mui/icons-material/Add";
import { nameTransform } from "../../../utils/utilsFunctions";
import { useParams } from "react-router-dom";
import theme from "../../../theme";
import { setMessage } from "../../../redux/slices/snackbarSlice";
const AddDeviceTable = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [getMeters] = store.useLazyGetMeterIndexQuery();
  const [getDevicePolicy] = store.useLazyGetDevicePolicyIndexQuery();
  const [addDevice, { isSuccess }] = store.useAddDeviceToPolicyMutation();
  const [firstRender, setFirstRender] = useState(true);
  const metersIndex = useSelector((state) => state?.meterSlice?.metersIndex);
  const metersIndexCount = useSelector(
    (state) => state?.meterSlice?.metersIndexCount
  );
  const { id } = useParams();
  const { t } = useTranslation();
  const [serial, setSerial] = useState("");

  const onSearchClick = () => {
    setFirstRender(false);
    getMeters({
      mechanical_serial_number: serial,
      page: page,
      per_page: 12,
    });
    setFirstRender(false);
  };

  useEffect(() => {
    if (!firstRender) {
      getMeters({
        mechanical_serial_number: serial,
        page: page,
        per_page: 12,
      });
    }
  }, [page]);

  const onAddClick = (deviceId) => {
    const attributes = {
      device_id: deviceId,
    };
    addDevice({
      policyId: id,
      attributes: attributes,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("device_added_successfully")));
      getDevicePolicy({ policyId: id, params: {} });
    }
  }, [isSuccess]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "network_address",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "condominium_name",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),

      sortable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "last_reeading_date",
      headerName: t("last_reeading_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Tooltip title={t("add_device")}>
              <IconButton onClick={() => onAddClick(params?.value)}>
                <AddIcon sx={{ fill: theme.palette.success.main }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  const rows = metersIndex?.map((item) => ({
    id: item.id ? item.id : "--",

    class: item?.attributes?.class
      ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--",
    network_address:
      item?.attributes?.fields?.[item?.attributes?.fields?.identifier_type],
    serial: item?.attributes?.fields?.serial
      ? item?.attributes?.fields?.serial
      : "--",
    /*   metrological_serial: item?.attributes?.fields?.serial ?? "--", */
    condominium_name: item?.attributes?.fields?.condominium_name ?? "--",
    apartment_name: item?.attributes?.fields?.apartment_name ?? "--",
    last_reeading_date: item?.attributes?.fields?.last_reading?.datetime_utc
      ? t("dt", {
          val: new Date(item?.attributes?.fields?.last_reading?.datetime_utc),
        })
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",

    configdate: item?.attributes?.inserted_at
      ? t("date_val", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: item.id ? item.id : "",
  }));

  return (
    <Box width={1200}>
      <TextField
        sx={{ mt: 2 }}
        fullWidth
        onChange={(e) => setSerial(e.target.value)}
        value={serial}
        label={t("metrological_serial")}
      />
      <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Button onClick={onSearchClick} sx={{ mt: 1 }} disabled={!serial}>
          {t("search")}
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        <IwdDataGrid
          columns={columns}
          perPage={12}
          setPage={setPage}
          rows={rows ?? []}
          page={page}
          count={metersIndexCount}
          paginationMode={"server"}
          sort={false}
          height={545}
          tableName={"add_device_to_policy_table"}
        />
      </Box>
    </Box>
  );
};

export default AddDeviceTable;
