import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GatewayRegistri from "./GatewayRegistry";
import { useTranslation } from "react-i18next";
import ReadedMeters from "./ReadedMeters";
import { cleanGatewayData, setGateway } from "../../redux/slices/gatewaySlice";
import { useMediaQuery } from "@mui/material";
import { setTabIndex } from "../../redux/slices/gatewaySlice";
import { setFieldsToUpdate } from "../../redux/slices/gatewaySlice";
import { notesActions } from "../../redux/slices/notesSlice";
import { store } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import WhiteList from "./WhiteList";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { useLazyGetDataQuery } from "../../redux/slices/GetDataRtq";
import { setMessage } from "../../redux/slices/snackbarSlice";
import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import DeleteButton from "../../utils/DeleteButton";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import GatewayMessages from "./GatewayMessages";
import theme from "../../theme";
import { setGwSerial } from "../../redux/slices/breadCrumbMeterSlice";
import CsvParser from "./CsvParser";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import GatewayCommands from "./GatewayCommands";
import GatewayNotes from "./GatewayNotes";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import MeterCommands from "../metrics/MeterCommands";
import { setOpen } from "../../redux/slices/snackbarSlice";
import { setGatewayTrashId } from "../../redux/slices/gatewaySlice";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const GatewayShow = ({ idFromCondominium = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trashModal, setTrashModal] = useState(null);
  const [deleteGateway, { isSuccess: deleteSuccess }] =
    store.useDeleteGatewayMutation();
  const gatewayTrashId = useSelector((state) => state?.gatewaySlice?.trashId);
  const tabIndex = useSelector((state) => state?.gatewaySlice?.gatewayTabIndex);
  const gatewayId = useSelector((state) => state?.gatewaySlice?.gatewayId);
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const fieldsToUpdate = useSelector(
    (state) => state?.gatewaySlice?.fieldsToUpdate
  );

  const { id } = useParams();
  const autoLoading = useSelector((state) => state?.loadingSlice?.autoLoading);
  const gateway = useSelector((state) => state?.gatewaySlice?.gateway);
  const edge = useSelector((state) => state?.gatewaySlice?.edgeShpw);
  const twinId = useSelector((state) => state?.gatewaySlice?.twinId);
  const [getShowGateway, { isError: gatewayError, isFetching }] =
    store.useLazyGetShowGatewayQuery();
  const [getGatewayMessages, { isSuccess }] =
    store.useLazyGetGatewayMessagesQuery();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  /*   const key = result?.data?.data?.attributes?.fields?.identifier_type;
  const identifierTypeValue = result?.data?.data?.attributes?.fields?.[key]; */
  /*   const serial = result?.data?.data?.attributes?.fields?.serial; */
  useEffect(() => {
    if (!idFromCondominium) {
      getShowGateway(id);
    } else {
      getShowGateway(idFromCondominium);
    }
  }, [id, idFromCondominium]);
  useEffect(() => {
    if (gatewayTrashId) {
      deleteGateway(gatewayTrashId);
      dispatch(setGatewayTrashId(null));
    }
  }, [gatewayTrashId]);
  useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      navigate("/gateways");
    }
  }, [deleteSuccess]);
  useEffect(() => {}, [idFromCondominium]);
  useEffect(() => {
    if (gateway) {
      dispatch(
        setBreadCrumbs({
          id: gateway?.serial_number,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [gateway]);
  useEffect(() => {
    if (gateway) {
      dispatch(
        setFieldsToUpdate({
          class: gateway?.class,
          instanceId: gatewayId,
          twinId: gateway?.digital_twin_id,
          name: gateway?.fields?.name,
          serial_number: gateway.serial_number,
          note: gateway?.fields?.note,
          time_zone: gateway?.fields?.time_zone,
          security: gateway?.fields?.security?.acl,
          msisdn: gateway?.fields?.msisdn,
          coordinates: {
            lat: gateway?.coordinates?.coordinates?.[1],
            lng: gateway?.coordinates?.coordinates?.[0],
          },
          iccid: gateway?.fields?.iccid,
        })
      );
    }
  }, [gateway]);
  useEffect(() => {
    dispatch(notesActions.cleanNotes());
  }, []);
  useEffect(() => {
    if (idFromCondominium && fieldsToUpdate) {
      localStorage.setItem("gatewayUpdFields", JSON.stringify(fieldsToUpdate));
    }
  }, [idFromCondominium, fieldsToUpdate]);
  const onUpdateClick = () => {
    if (!idFromCondominium) {
      navigate("edit");
    } else {
      window.open(`/gateways/${fieldsToUpdate.instanceId}/edit`, "_blank");
    }
  };
  const handleChange = (event, newValue) => {
    dispatch(setTabIndex(newValue));
  };

  useEffect(() => {}, [gateway]);

  useEffect(() => {
    dispatch(setGwSerial(gateway?.serial_number));
    return () => dispatch(setGwSerial(null));
  }, [gateway]);
  return (
    <Box width={"100%"}>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("gateway_delete_text")}
        title={t("gateway_delete_title")}
        deleteFunc={() => setGatewayTrashId(id)}
      />
      {gateway &&
        !isFetching &&
        (gateway.class === "gateway" || gateway.class === "Gateway") && (
          <>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box width={"50%"}>
                <Typography variant="h4">{`${t("gateway")}: ${
                  gateway?.serial_number
                }`}</Typography>
              </Box>
              <Box>
                <IwdAuthWrapper
                  children={
                    <Button
                      variant={window.BUTTON_TYPE}
                      sx={{ mt: 2, mr: 2, height: 40 }}
                      onClick={onUpdateClick}
                    >
                      {t("update")}
                    </Button>
                  }
                  section={"upd_gateway_btn"}
                />
                <DeleteButton
                  name={t("delete")}
                  onClick={() => setTrashModal(true)}
                  styles={{ height: 40, mt: 2 }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "grid", gridAutoColumns: "1fr", gap: 1 }}>
              <Box
                sx={{
                  gridRow: "1",
                  gridColumn: "span 2",
                  width: "100%",
                }}
              >
                <Tabs value={tabIndex} onChange={handleChange}>
                  <Tab label={t("registry")} />
                  <Tab label={t("manufacturer_data")} />
                  <Tab label={t("messages")} /* disabled={!isSuccess} */ />

                  <Tab
                    label={t("commands")}
                    disabled={gateway?.fields?.digital_twin_name !== "RBM Box"}
                  />
                  <Tab label={t("meters_read")} />

                  <Tab label={t("white_list")} />
                  <IwdAuthWrapper
                    children={<Tab label={t("notes")} />}
                    section={"gateway_notes_tab"}
                  />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                  <GatewayRegistri
                    gateway={gateway}
                    idFromCondominium={idFromCondominium}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <Typography variant="subtitle2">
                    {t("name") + ":"}{" "}
                  </Typography>
                  <Typography>{gateway?.fields?.manufacturer} </Typography>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <GatewayMessages
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    idFromCondominium={idFromCondominium}
                    id={id}
                    twinId={twinId}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                  <MeterCommands />
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                  <ReadedMeters idFromCondominium={idFromCondominium} />
                </TabPanel>

                <TabPanel value={tabIndex} index={5}>
                  <WhiteList />
                </TabPanel>
                <IwdAuthWrapper
                  children={
                    <TabPanel value={tabIndex} index={6}>
                      <GatewayNotes idFromCondominium={idFromCondominium} />
                    </TabPanel>
                  }
                  section={"gateway_notes_tab"}
                />
              </Box>
            </Box>
          </>
        )}
      {!gateway && !autoLoading && gatewayError && (
        <Typography variant="h4" align="center">
          Concentratore non trovato
        </Typography>
      )}
    </Box>
  );
};

export default GatewayShow;
