import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WifiIcon from "@mui/icons-material/Wifi";
import { useNavigate } from "react-router-dom";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import WifiOffIcon from "@mui/icons-material/WifiOff";

import Tooltip from "@mui/material/Tooltip";
import { SizeMe } from "react-sizeme";
import WsConnector from "../utils/WsConnector";
import { Link } from "react-router-dom";
import { useUpdateWidgetsIdMutation } from "../redux/slices/dashboardApi";
import { useSetPreferenceMutation } from "../redux/slices/userPreferenceApi";
import { clearWidget } from "../redux/slices/widgetsSlice";
import SearchOnServer from "../components/form/formComponents/SearchOnServer";
import { store } from "../redux/store";
import DescriptionIcon from "@mui/icons-material/Description";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";
import { setMessage } from "../redux/slices/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PsychologyIcon from "@mui/icons-material/Psychology";
import DescriptionModal from "../components/dashboard/DescriptionModal";
import ErrorsHandler from "../utils/ErrorsHandler";
import { useEffect, useState, useRef } from "react";

import Uploader from "../components/FileUploader /Uploader";
import GridLayout from "react-grid-layout";
import PieCharWidget from "../components/dashboard/widgets/PieChartWidget";
import {
  createOrUpdate,
  updateDashboardData,
  updatingOff,
  updatingOn,
  addWidgetId,
  addWidget,
  clearWidgets,
  deleteFromDashboardList,
  setDashboardId,
  setTempWidgets,
  clearTempWidgets,
  restoreWidgets,
  setIsDragging,
  deleteTempWidgets,
  clearRestoreWidgets,
} from "../redux/slices/dashboardSlice";
import { setPreferenceDashboardId } from "../redux/slices/userPreferenceSlice";
import { useLazyGetWidgetsQuery } from "../redux/slices/widgetsApi";
import SingleValuewidget from "../components/dashboard/widgets/SingleValueDashboard";
import { useLazyGetDataQuery } from "../redux/slices/GetDataRtq";
import { useLazyGetPreferenceQuery } from "../redux/slices/userPreferenceApi";
import { Responsive, WidthProvider } from "react-grid-layout";
import "../../node_modules/react-grid-layout/css/styles.css";
import "../../node_modules/react-resizable/css/styles.css";
import SaveIcon from "@mui/icons-material/Save";
import DashBoardSelect from "../components/dashboard/DashboardSelect";
import WidgetSelect from "../components/dashboard/widgets/WidgetSelect";

import theme from "../theme";
import { MuiThemeProvider, createTheme } from "@material-ui/core";

import IconButton from "@mui/material/IconButton";
import { loadingOn, loadingOff } from "../redux/slices/loadingSlice";
import DashboardModal from "../components/dashboard/DasboardModal";
import GlodablLoading from "../utils/GlobalLoading";
import AddIcon from "@mui/icons-material/Add";
import test from "../redux/slices/CampaignSlice";
import { actions } from "../redux/slices/iwdWebSocketSlice";
import { selectType } from "../components/dashboard/widgets/utils/widgetConfigurations";
import clusterSlice from "../redux/slices/clusterSlice";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Typography,
} from "@mui/material";

import { Fullscreen } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { array } from "@jsonforms/examples";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Metrics = () => {
  const { t } = useTranslation();
  const dashboardDecription = useSelector(
    (state) => state?.dashboardSlice?.description
  );
  const [
    getDashboardList,
    { error: errorIndex, isFetching: indexFetch, isSuccess: listSuccess },
  ] = store.useLazyGetDashboardListQuery();
  const [showMsg, setShowMsg] = useState(false);
  const dashboardLayout = useSelector((state) => state?.dashboardSlice?.layout);
  const dashboard = useSelector((state) => state?.dashboardSlice?.widgets);
  /*   const [isDragging, setIsDragging] = useState(false); */
  const dragBlock = useSelector((state) => state?.dashboardSlice?.dragBlock);
  const dashWidgets = useSelector(
    (state) => state?.dashboardSlice?.dashboard?.widgets
  );
  const isDragging = useSelector((state) => state?.dashboardSlice?.isDragging);
  const [mappedWidgets, setMappedWidgets] = useState([]);
  const last_update = useSelector(
    (state) => state?.dashboardSlice?.dashboard?.last_update
  );
  const dashboardId = useSelector(
    (state) => state?.dashboardSlice?.dashboardId
  );
  const preferenceDashboardId = useSelector(
    (state) => state?.userPreferenceSlice?.dashboardId
  );
  const dashboardShow = useSelector(
    (state) => state?.dashboardSlice?.dashboardShow
  );
  const firstDashboardIdFromList = useSelector(
    (state) => state?.dashboardSlice?.dashboards
  ).find((el) => el.id !== preferenceDashboardId)?.id;
  const dashboardWidgetIds = useSelector(
    (state) => state.dashboardSlice?.widget_ids
  );
  const widgets = useSelector(
    (state) => state?.widgetsSlice?.widgets?.attributes?.data
  );
  const widgetShow = useSelector(
    (state) => state?.widgetsSlice?.widgetShow?.attributes
  );
  const dashboardName = useSelector(
    (state) => state?.dashboardSlice?.dashboard?.name
  );
  const dashboardList = useSelector(
    (state) => state?.dashboardSlice?.dashboards
  );
  const preference = useSelector(
    (state) => state?.userPreferenceSlice?.preference
  );
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  const preferenceUpdating = useSelector(
    (state) => state?.userPreferenceSlice?.updating
  );
  useEffect(() => {
    dispatch(setBreadCrumbs("Metrics"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {
    if (dashboard) {
      setMappedWidgets(
        dashboard?.map((widget) =>
          selectType(widget, edit, setTrashModal, setTrashId)
        )
      );
    }
  }, [dashboard]);

  useEffect(() => {
    console.log("dashik", dashboard);
  }, [dashboard]);

  const navigate = useNavigate();

  const [getShowWidget, resultShowWidfet] = store.useLazyGetShowWidgetsQuery();
  const [
    getDashboardShow,
    { isSuccess: dashSuccess, error: dashShowErr, isFetching: showFetch },
  ] = store.useLazyGetDashboardShowQuery();
  /*  const [getWidget, res] = useLazyGetWidgetsQuery(); */
  const [getPreference] = store.useLazyGetPreferenceQuery();
  const [cols, setColls] = useState({ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 });
  const [breakpoints, setBreakpoints] = useState({
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  });
  const [zalupa, setZalupa] = useState(1200);
  const [removeDashboard, setRemoveDashboard] = useState(false);
  /*   const [dashboardId, setDashboardId] = useState(1); */
  const [formData, setFormData] = useState({});
  const [firstLayoutRender, setFirstLayoutRender] = useState(true);
  const { innerWidth: width, innerHeight: height } = window;
  const storageWidgts = JSON.parse(localStorage.getItem("wid"));
  const [pos, setPoss] = useState([]);

  const [descriptionModal, setDescriptionModal] = useState(false);
  const [layout, setLayout] = useState(null);
  const [trashModal, setTrashModal] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [deletePreference, setDeletePreference] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const [load, setLoad] = useState(true);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [updateWidgetsId, { isSuccess: updSuccess }] =
    store.useUpdateWidgetsIdMutation();
  const [setPreference, { isSuccess, isLoading: preferenceLoading }] =
    store.useSetPreferenceMutation();
  const widgetsToRestore = useSelector(
    (state) => state?.dashboardSlice?.widgetsToRestore
  );
  const {
    isWSConnected,
    data: dataDashboardWs,
    error,
    channels,
    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);
  const onDescriptionClick = () => {
    setDescriptionModal(true);
  };

  useEffect(() => {}, [isSuccess]);
  useEffect(() => {
    getDashboardList();
  }, []);
  useEffect(() => {
    setTrashModal(setLoad(false), 5000);
  }, []);
  /*   useEffect(() => {
    if (!firstRender) {
      setMessage(t("add_widget"));
    }
  }, [formData]); */
  useEffect(() => {
    if (dashShowErr && isSuccess) {
      window.location.reload();
    }
  }, [dashShowErr, isSuccess]);
  useEffect(() => {
    getPreference();
  }, []);
  useEffect(() => {
    return () => dispatch(clearWidgets());
  }, []);
  useEffect(() => {
    if (edit) {
      dispatch(updatingOff());
    } else {
      dispatch(updatingOn());
    }
  }, [edit]);
  const onBreakpiontsChange = (breakpoint, cols) => {
    setBreakpoints(breakpoint);
    setColls(cols);
  };
  useEffect(() => {
    if (dashboardLayout) {
      setFirstLayoutRender(false);
    }
  }, [dashboardLayout]);
  useEffect(() => {
    if (!firstLayoutRender) {
      setLayout(dashboardLayout);
    }
  }, [firstLayoutRender]);
  useEffect(() => {}, [dashShowErr]);
  const schema = {
    type: "object",
    properties: {
      widgets: {
        title: t("select_widget"),
        url: "plugin/widgets",
        id: 1,
        type: "string",
        widgetSelect: true,
        filteredWidgets: dashboardWidgetIds,
      },
    },
  };
  const muiTheme = createTheme({
    props: {
      MuiTextField: {
        variant: "outlined",
      },
    },
  });

  const onDeleteClick = () => {
    if (dashboardId == preferenceDashboardId) {
      setDeletePreference(true);
    } else {
      setDeletePreference(false);
    }
    setTrashModal(true);
    setRemoveDashboard(true);
  };
  const widgetDimensions = {
    single_value: { w: 2, h: 2 },
    pie_chart: { w: 4, h: 2 },
    multiple_value: { w: 3, h: 2 },
    line_chart: { w: 4, h: 2 },
    map: { w: 12, h: 2.6 },
  };

  const getWidgetDimensions = (type) =>
    widgetDimensions[type] || { w: 2, h: 1 };
  const onLayoutChange = (layout) => {
    if (isDragging) {
      console.log("layka", layout);
      let temp = layout.map((item) => {
        const widget = dashboard.find((w) => w.id == item.i);
        console.log("widyaka", widget);
        const { w, h } = getWidgetDimensions(widget?.type);
        console.log("w:", w, "h:", h);
        return {
          w,
          h,
          x: item.x,
          y: item.y,
          i: item.i,
        };
      });

      setLayout(temp);
    }
  };
  const handleDragStart = () => {
    dispatch(setIsDragging(true));
  };

  const handleDragStop = () => {
    dispatch(setIsDragging(true));
  };
  const onStartLayoutChange = () => {
    let temp = dashboardLayout.map((item) => {
      const widget = dashboard.find((w) => w.id == item.i);
      console.log("widyaka", widget);
      const { w, h } = getWidgetDimensions(widget?.type);
      console.log("w:", w, "h:", h);
      return {
        w,
        h,
        x: item.x,
        y: item.y,
        i: item.i,
      };
    });
    setLayout(temp);
  };

  const uiSchema = {
    "ui:submitButtonOptions": {
      norender: "true",
    },

    widgets: {
      "ui:field": "serverSearch",
    },
  };
  const fields = {
    serverSearch: SearchOnServer,
  };
  useEffect(() => {
    if (!firstRender && dashboardId) {
      if (isWSConnected) {
        dispatch(actions.cleanData("data_update"));
        dispatch(actions.leaveChannel("plugin:dashboards"));
        dispatch(actions.disconnect());
      }

      getDashboardShow(dashboardId);
    }
  }, [dashboardId]);

  /*  useEffect(() => {
    if (dashShowErr) {
      dispatch(actions.cleanData("data_update"));
      dispatch(actions.leaveChannel("plugin:dashboards"));
      dispatch(actions.disconnect());
    }
  }, [dashShowErr]); */
  useEffect(() => {}, [dashboardId]);
  useEffect(() => {}, [dashboard]);
  useEffect(() => {
    if (
      preferenceDashboardId !== null &&
      firstRender &&
      preferenceDashboardId !== "nodash" &&
      preferenceDashboardId
    ) {
      Promise.all([
        getDashboardShow(preferenceDashboardId),
        dispatch(setDashboardId(preferenceDashboardId)),
        ,
      ])?.then(() => setFirstRender(false));
    }
  }, [preferenceDashboardId]);
  useEffect(() => {
    if (!preferenceDashboardId && dashboardList.length > 0 && firstRender) {
      getDashboardShow(dashboardList?.[0]?.id).then(() => {
        setFirstRender(false);
        dispatch(setPreferenceDashboardId(dashboardList?.[0]?.id));
      });
    }
  }, [dashboardList]);

  useEffect(() => {
    if (dashboardShow) {
      dispatch(createOrUpdate(dashboardShow));
    }
  }, [dashboardShow]);

  useEffect(() => {}, [widgets]);
  useEffect(() => {
    if (formData?.widgets) {
      dispatch(addWidgetId(formData?.widgets));
      getShowWidget({ id: formData?.widgets });
    }
  }, [formData?.widgets]);

  useEffect(() => {}, [errorIndex]);

  useEffect(() => {
    setShowMsg(true);
    if (widgetShow) {
      dispatch(addWidget(widgetShow));

      if (widgetShow?.name && showMsg) {
        dispatch(setMessage(`${t("widget_added")} : ${widgetShow?.name}`));
        dispatch(setIsDragging(true));
        dispatch(setTempWidgets(widgetShow?.id));
      }
    }
  }, [widgetShow]);
  useEffect(() => {
    console.log("wdgthsw", widgetShow);
  }, [widgetShow]);

  useEffect(() => {
    if (updSuccess) {
      dispatch(actions.cleanData("data_update"));
      dispatch(actions.leaveChannel("plugin:dashboards"));
      dispatch(actions.disconnect());
      dispatch(setMessage(t("update_success")));
      getDashboardShow(dashboardId);
    }
  }, [updSuccess]);
  const onSaveClick = () => {
    const body = {
      data: {
        attributes: {
          widget_ids: dashboardWidgetIds,
          layout: layout,
        },
        type: "plugin",
        id: dashboardId.toString(),
      },
    };
    updateWidgetsId({ body: body, id: dashboardId });

    /*    getDashboardList(); */
    dispatch(clearTempWidgets());
    dispatch(clearRestoreWidgets());
    setEdit(false);
  };

  const onSavePreferenceClick = (id) => {
    dispatch(setPreferenceDashboardId(dashboardId));
  };

  useEffect(() => {
    if (preferenceUpdating) {
      setPreference(preference);
    }
  }, [preferenceUpdating]);

  const onEditClick = () => {
    setEdit(!edit);
  };

  const onCancellClick = () => {
    const idsToRestore = widgetsToRestore?.map((item) => item?.id);
    setEdit(false);
    dispatch(deleteTempWidgets());
    dispatch(clearTempWidgets());
    dispatch(restoreWidgets(idsToRestore));
    dispatch(clearRestoreWidgets());
    setLayout(dashboardLayout);
  };

  useEffect(() => {
    console.log("dashka", dashboardLayout);
  }, [dashboardLayout]);

  useEffect(() => {
    return () => {
      dispatch(actions.cleanData("data_update"));
    };
  }, []);

  const notFoundPrefferedDashboard = () => {
    dispatch(setPreferenceDashboardId(null));
    navigate(`/metrics/create`);
  };

  if (
    !preferenceDashboardId &&
    dashboardList?.length < 1 &&
    dashShowErr?.status !== "422" &&
    errorIndex?.status !== "422" &&
    errorIndex?.status !== "FETCH_ERROR" &&
    !indexFetch &&
    listSuccess
  ) {
    dispatch(loadingOff());
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4">No dashboards</Typography>
        <IconButton component={Link} to="/metrics/create">
          <Tooltip title={t("create_dashboard")}>
            <AddIcon />
          </Tooltip>
        </IconButton>
      </Box>
    );
  }
  if (dashShowErr?.status == "404") {
    dispatch(loadingOff());
    return (
      <>
        <Typography textAlign="center" variant="h4">
          {t("no_pref_dash")}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <DashBoardSelect disabled={edit} reset={true} />
        </Box>
      </>
    );
  }

  return (
    <>
      {dashboardLayout && layout && (
        <Box sx={{ mb: 4, p: 2 }}>
          {" "}
          {/* Уменьшение отступов для мобильных */}
          <DescriptionModal
            text={dashboardDecription}
            open={descriptionModal}
            close={() => setDescriptionModal(false)}
          />
          <DashboardModal
            trashModal={trashModal}
            setTrashModal={setTrashModal}
            trashId={trashId}
            removeDashboard={removeDashboard}
            dashboardId={dashboardId}
            deletePreference={deletePreference}
            firstDashboardIdFromList={firstDashboardIdFromList}
            onChangePreverence={() =>
              onSavePreferenceClick(
                firstDashboardIdFromList ? firstDashboardIdFromList : null
              )
            }
            updatePreferenceSuccess={isSuccess}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                width: { xs: "100%", sm: "15%" },
                textAlign: { xs: "center", sm: "left" },
                mb: { xs: 2, sm: 0 },
              }}
            >{`${t("last_update")}: ${t("dt", {
              val: new Date(last_update),
            })}`}</Typography>

            {!edit ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: { xs: "100%", sm: "60%" },
                  mt: { xs: 2, sm: 0 },
                }}
              >
                <IconButton component={Link} to="/metrics/create">
                  <Tooltip title={t("create_dashboard")}>
                    <AddIcon sx={{ fill: theme.palette.secondaryIcons }} />
                  </Tooltip>
                </IconButton>
                <IconButton>
                  <Tooltip
                    title={
                      dashboardId === preferenceDashboardId
                        ? t("preference_selected")
                        : t("save_preference")
                    }
                  >
                    <PsychologyIcon
                      sx={{
                        fill:
                          dashboardId === preferenceDashboardId
                            ? "green"
                            : theme.palette.secondaryIcons,
                      }}
                      onClick={() => onSavePreferenceClick(dashboardId)}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton>
                  <Tooltip title={t("description")}>
                    <DescriptionIcon
                      sx={{ fill: theme.palette.secondaryIcons }}
                      onClick={onDescriptionClick}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton>
                  <Tooltip title={t("edit_dashboard")}>
                    <EditIcon
                      sx={{ fill: theme.palette.secondaryIcons }}
                      onClick={onEditClick}
                    />
                  </Tooltip>
                </IconButton>

                <IconButton>
                  <Tooltip title={t("delete_dashboard")}>
                    <DeleteIcon
                      sx={{ fill: theme.palette.error.main }}
                      onClick={onDeleteClick}
                    />
                  </Tooltip>
                </IconButton>
                <Box sx={{ width: "100%" }}>
                  <DashBoardSelect
                    disabled={edit}
                    preferenceDashboardId={preferenceDashboardId}
                  />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: "center",
                  width: "100%",
                  ml: { xs: 0, sm: 5 },
                }}
              >
                <Typography variant="h5" sx={{ mt: 2, mr: 2 }}>
                  {`${t("editing")} : ${dashboardName}`}
                </Typography>
                <Box sx={{ width: { xs: "100%", sm: "31%" }, ml: { sm: 2 } }}>
                  <Form
                    schema={schema}
                    fields={fields}
                    uiSchema={uiSchema}
                    formData={formData}
                    onChange={(changeEvent) =>
                      setFormData(changeEvent.formData)
                    }
                    validator={validator}
                  />
                </Box>
                <Button
                  sx={{ ml: { sm: 2 }, mt: 2, height: 54 }}
                  onClick={onSaveClick}
                >
                  {t("save")}
                </Button>

                <Button
                  sx={{ mt: 2, ml: 2, height: 54 }}
                  onClick={onCancellClick}
                >
                  {t("cancel")}
                </Button>
              </Box>
            )}
            {preferenceLoading && (
              <Tooltip title="saving user preference">
                <SaveIcon sx={{ mr: 1, fill: "green" }} />
              </Tooltip>
            )}
            {dashboardLayout && (
              <WsConnector
                channelName={"plugin:dashboards"}
                channelParams={{ dashboard_id: dashboardId }}
                eventName={"data_update"}
                setDataFunction={updateDashboardData}
                manualClean
              />
            )}
          </Box>
          <Box>
            <ResponsiveGridLayout
              style={{ marginTop: 20, userSelect: "none" }}
              className="layout"
              layouts={{
                lg: layout,
                md: layout,
                sm: layout,
                xs: layout,
                xxs: layout,
              }}
              isResizable={false}
              measureBeforeMount={false}
              breakpoints={breakpoints}
              cols={cols}
              rowHeight={190}
              margin={[20, 20]}
              containerPadding={[20, 20]}
              onLayoutChange={edit ? onLayoutChange : onStartLayoutChange}
              isDraggable={edit && !dragBlock}
              onDragStart={handleDragStart} // Начало перетаскивания
              onDragStop={handleDragStop}
              verticalCompact={true}
              preventCollision={false}
              compactType="vertical"
            >
              {dashboard?.map((widget) =>
                selectType(widget, edit, setTrashModal, setTrashId)
              )}
            </ResponsiveGridLayout>
          </Box>
          <Box sx={{ mt: 10, height: 100 }}></Box>
        </Box>
      )}
      {(dashShowErr?.status == "422" ||
        errorIndex?.status == "422" ||
        errorIndex?.status == "FETCH_ERROR") && (
        <Typography variant="h4" textAlign={"center"}>
          {t("render_error")}
        </Typography>
      )}
      {/* Удален закомментированный код для чистоты */}
    </>
  );
};
export default Metrics;
