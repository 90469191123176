import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import { nameTransform } from "../../../utils/utilsFunctions";
import { debug } from "util";
const effelist = [
  "EFE:19:08",
  "EFE:20:08",
  "EFE:21:08",
  "EFE:22:08",
  "EFE:23:08",
  "EFE:24:08",
  "EFE:25:08",
  "EFE:26:08",
  "EFE:27:08",
  "EFE:28:08",
  "EFE:29:08",
  "EFE:30:08",
  "EFE:31:08",
  "EFE:32:08",
  "EFE:33:08",
  "EFE:34:08",
  "EFE:35:08",
  "EFE:36:08",
  "EFE:37:08",
  "EFE:38:08",
  "EFE:39:08",
  "EFE:40:08",
  "EFE:41:08",
  "EFE:42:08",
  "EFE:43:08",
  "EFE:44:08",
  "EFE:45:08",
  "EFE:46:08",
  "EFE:47:08",
  "EFE:48:08",
  "EFE:49:08",
];

export default function HeatMeterSelect({
  obj,
  setKc,
  kc,
  meterName,
  update,
  setFc,
  selectedMeter,
}) {
  const { t } = useTranslation();
  const [firstRender, setFirstRender] = useState(true);
  const [firstInit, setFirstInit] = useState(true);
  const [selectedLabel, setSelectedLabel] = useState();

  const meterType = useSelector((state) => state?.meterSlice?.selectedMeter)
    ?.attributes?.digital_twin_fields?.manufacturer;
  const meterClass = useSelector((state) => state?.meterSlice?.selectedMeter)
    ?.attributes?.digital_twin_fields?.class;
  const [age, setAge] = React.useState("");
  const types = Object.entries(obj)?.filter((item) =>
    item[0].includes(meterType == "INE" ? "EFE" : meterType)
  );
  let transformedClass = meterClass && nameTransform(meterClass);

  const effemode = effelist.includes(transformedClass);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setKc(selectedValue);

    const selectedType = types.find((item) => item[1] === selectedValue);
    if (selectedType) {
      setSelectedLabel(selectedType[0]);
    }
  };

  React.useEffect(() => {
    if (effemode) {
      if (selectedLabel == "kcEFE") {
        setFc(2.288);
      } else if (selectedLabel == "kcEFEse") {
        setFc(1.097);
      }
    } else {
      setFc(1);
    }
  }, [selectedLabel, effemode]);

  /*  React.useEffect(() => {
    if (selectedMeter.length > 0) {
      setFc(1);
    }
  }, [selectedMeter]); */

  React.useEffect(() => {
    Object.keys(types)?.forEach((key) => {});
  }, [types]);
  React.useEffect(() => {}, [types, obj, meterType, selectedMeter]);

  React.useEffect(() => {
    if (!firstRender) {
      setFirstRender(true);
      setFirstInit(true);
      setKc(types?.[0]?.[1]);
    }
  }, [meterName]);

  React.useEffect(() => {
    if (meterType == "BMP" /* || meterType == "INE" */) {
      setKc(1);
      setFc(1);
    }
  }, [meterType]);

  React.useEffect(() => {
    console.log("metertype", meterType);
  }, [meterType]);

  React.useEffect(() => {
    if ((types.length > 0 && firstRender && !update) || !firstInit) {
      setKc(types?.[0]?.[1]);
      setFirstRender(false);
      if (effemode) {
        setFc(2.288);
      } else {
        setFc(1);
      }
    }
    if (update) {
      setFirstRender(false);
    }
  }, [types]);

  React.useEffect(() => {
    if (update && !firstRender && effemode) {
      setFc(2.288);
    }
  }, [firstRender, effemode]);

  return (
    <Box sx={{ width: "95%" }}>
      <FormControl fullWidth disabled={meterType == "BMP"}>
        <InputLabel id="demo-simple-select-label">
          Tipo installazione
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={kc}
          label="Tipo instalazione"
          onChange={handleChange}
        >
          {types?.map((item) => (
            <MenuItem key={item?.[1]} value={item?.[1]}>
              {t(item?.[0])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
