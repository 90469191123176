import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { store } from "../../../redux/store";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";

import debounce from "lodash.debounce";

import { useDispatch, useSelector } from "react-redux";

const SupplyUserWidget = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultProp = props?.schema?.default ?? props.default;
  const err = props?.rawErrors?.[0];
  const [searchValue, setSearchValue] = useState("");

  const update = props?.schema?.update;
  const [firstRender, setFirstRender] = useState(true);
  const users = useSelector((state) => state?.userSlice?.users);
  const mappedUsers = users?.map((user) => ({
    label: `${user?.attributes?.first_name} ${user?.attributes?.last_name}`,
    id: user?.attributes?.id,
  }));
  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  const [getUsers] = store.useLazyGetUsersQuery();
  const [value, setValue] = useState();

  useEffect(() => {
    if (!value && !searchValue) {
      getUsers();
    }
  }, [value, searchValue]);

  const defaultValue = useSelector((state) => state?.userSlice?.savedUser);

  useEffect(() => {
    if (value) {
      props.onChange(value.id ?? defaultValue.id);
    } else {
      props.onChange(update ? defaultValue?.id : "");
    }
  }, [value, defaultValue]);

  useEffect(() => {
    console.log("popsi4esky", props);
  }, [props]);
  useEffect(() => {
    if (searchValue) {
      getUsers({ username: searchValue });
    }
  }, [searchValue]);

  return (
    <>
      {mappedUsers && !update && (
        <Autocomplete
          fullWidth
          disablePortal
          value={value}
          id="combo-box-demo"
          options={mappedUsers ?? []}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          onInputChange={(e, newInputValue) => {
            setSearchValue(newInputValue);
          }}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              error={err}
              {...params}
              label={
                props?.label ?? `${t("user")} ${props?.required ? "*" : ""}`
              }
            />
          )}
        />
      )}
      {mappedUsers && defaultValue && update && (
        <Autocomplete
          fullWidth
          disablePortal
          value={value}
          id="combo-box-demo"
          options={mappedUsers ?? []}
          onChange={(event, newInputValue) => {
            setValue(newInputValue);
          }}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField
              error={err}
              {...params}
              label={
                props?.label ?? `${t("user")} ${props?.required ? "*" : ""}`
              }
            />
          )}
        />
      )}
      {!mappedUsers && (
        <Autocomplete
          fullWidth
          disablePortal
          disabled
          id="combo-box-demo"
          options={[]}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                props?.label ?? `${t("user")} ${props?.required ? "*" : ""}`
              }
            />
          )}
        />
      )}
    </>
  );
};
export default SupplyUserWidget;
