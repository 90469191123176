import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useEffect, useState } from "react";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { store } from "../../../redux/store";

import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash.debounce";

const SearchOnServer = (props) => {
  const [value, setValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);

  const defPropValue = props?.schema?.default;
  const savedValue =
    props?.schema?.multiple &&
    props?.formData?.map((item) => ({
      label: item,
      value: item,
    }));
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  let res;
  if (props?.schema?.widgetSelect) {
    res = resp?.data?.data?.attributes?.data?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  } else {
    res = !props?.schema?.multiple
      ? resp?.data?.data?.map((item) => ({
          label: `${item?.attributes?.name} (${item?.attributes?.version})`,
          value: item?.attributes?.id,
        }))
      : resp?.data?.data?.map((item) => ({
          label: item?.attributes?.name,
          value: item?.attributes?.name,
        }));
  }
  if (props?.schema?.roles) {
    res = resp?.data?.data?.map((item) => ({
      label: item?.attributes?.key,
      value: item?.attributes?.key,
    }));
  }

  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  useEffect(() => {}, [props]);
  useEffect(() => {
    if (value && !props?.schema?.multiple) {
      props.onChange(value.value);
    }
    if (!value && !props?.schema?.multiple) {
      props.onChange(null);
    }
  }, [value]);
  //1
  useEffect(() => {
    if (value?.length >= 0) {
      props.onChange(value?.map((item) => item.value));
    }
  }, [value]);

  //2
  useEffect(() => {
    if (
      defPropValue &&
      defPropValue !== null &&
      defPropValue !== "" &&
      firstRender &&
      (!resp || !resp.data) &&
      props?.schema?.title !== "Roles"
    ) {
      setLoading(true);
      Promise.all([getByName([props.schema.url, {}])]).then(() => {
        setSearchValue(null);
      });
    }
  }, [defPropValue]);

  useEffect(() => {
    console.log("savedValue", savedValue);
  }, [savedValue]);
  //3
  useEffect(() => {
    if (
      defPropValue &&
      resp &&
      resp.data &&
      firstRender &&
      !props?.schema?.multiple &&
      props?.schema?.title !== "Roles"
    ) {
      const val = resp?.data?.data
        ?.filter((item) => defPropValue?.[0].includes(item.id))
        ?.map((item) => ({
          label: `${item?.attributes?.name} (${item?.attributes?.version})`,
          value: item?.attributes?.id,
        }));
      if (val?.length > 0) {
        setValue(val[0]);
      } else {
        setValue(null);
      }

      setLoading(false);
      setFirstRender(false);
    }
  }, [resp]);
  useEffect(() => {
    if (
      defPropValue &&
      resp &&
      resp.data &&
      firstRender &&
      props?.schema?.multiple &&
      props?.schema?.title !== "Roles"
    ) {
      setValue(
        resp?.data?.data
          ?.filter((item) => defPropValue?.[0].includes(item.attributes.name))
          ?.map((item) => ({
            label: item?.attributes?.name,
            value: item?.attributes?.name,
          }))
      );

      setLoading(false);
      setFirstRender(false);
    }
  }, [resp]);

  const openFunc = () => {
    setOpen(true);

    setLoading(true);
  };
  useEffect(() => {}, [value, props?.formData]);

  useEffect(() => {
    if (props?.schema?.title === "Roles" && defPropValue) {
      setValue(defPropValue);
    }
  }, [defPropValue]);

  useEffect(() => {
    if (open) {
      Promise.all([getByName([props.schema.url, {}])]).then(() => {
        setLoading(false);
        setFirstRender(false);
        setSearchValue(null);
      });
    }
  }, [open]);
  //5
  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      Promise.all([getByName([props.schema.url, { name: searchValue }])]).then(
        () => {
          setLoading(false);
          setSearchValue(null);
        }
      );
    }
  }, [searchValue]);

  useEffect(() => {
    console.log("selectedvalix", value);
  }, [value]);

  const uniqueValues = [
    ...new Set([
      ...(Array.isArray(savedValue)
        ? savedValue.map((item) => item.value)
        : []),
      ...(Array.isArray(value) ? value.map((item) => item.value) : []),
    ]),
  ];

  console.log(uniqueValues); // [1, 2]

  return (
    <>
      <Autocomplete
        multiple={props?.schema?.multiple}
        fullWidth
        options={
          res ? res?.filter((item) => !uniqueValues?.includes(item?.value)) : []
        }
        value={savedValue?.length > 0 ? savedValue : value}
        open={open}
        onOpen={openFunc}
        getOptionDisabled={(option) =>
          props?.schema?.filteredWidgets &&
          props?.schema?.filteredWidgets?.includes(option.value)
        }
        onClose={() => setOpen(false)}
        popupIcon={""}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <TextField
              onChange={debValue}
              {...params}
              value={savedValue?.length > 0 ? savedValue : value}
              error={props?.rawErrors?.length > 0}
              required={props.required}
              label={props.schema.title}
              InputProps={{
                ...params.InputProps,
                /*  endAdornment: (
                  <InputAdornment position="end">
                    {loading && <CircularProgress color="inherit" size={20} />}
                  </InputAdornment>
                ), */
              }}
            />
          );
        }}
      />
    </>
  );
};

export default React.memo(SearchOnServer);
