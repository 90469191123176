import * as React from "react";
import { Tooltip } from "@mui/material";
const ExternalValve = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Capa_1"
    width="30"
    height="30"
    fill="#000"
    version="1.1"
    viewBox="0 0 60 60"
  >
    <path
      id="SVGRepo_iconCarrier"
      d="M59 32h-6a1 1 0 0 0-1 1v1h-8v-7a1 1 0 0 0-1-1h-4v-3h6a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-1V3a1 1 0 0 0-1-1H19a1 1 0 0 0-1 1v2h-1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h6v3h-4a1 1 0 0 0-1 1v7H8v-1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v24a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-1h44v1a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V33a1 1 0 0 0-1-1m-1 12h-4v-4h4zm-4 2h4v4h-4zm0-12h4v4h-4v-4M34 21V7h2v14zm-4 0V7h2v14zm-4 0V7h2v14zm-4 0V7h2v14zM38 7h2v14h-2zm6 14h-2V7h2zM20 4h22v1H20zm-2 3h2v14h-2zm7 16h12v3H25zM6 44H2v-4h4zm-4 2h4v4H2zm0-12h4v4H2zm4 22H2v-4h4v4m2-2V36h11a1 1 0 0 0 1-1v-7h22v7a1 1 0 0 0 1 1h9v18zm50 2h-4v-4h4z"
    ></path>
  </svg>
);

export default ExternalValve;
