import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import { TextField, Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { store } from "../../redux/store";

const DistrictSearchBar = ({ params }) => {
  const { t } = useTranslation();
  const [getDistricts] = store.useLazyGetDistrictsQuery();
  const [searchValue, setSearchValue] = useState("");
  const onSearchClick = () => {
    const newParams = {
      ...params,
      name: searchValue,
    };
    getDistricts(newParams);
  };
  return (
    <Box>
      <Stack direction="row">
        <FormControl sx={{ width: "98%" }}>
          <TextField
            id="standard-basic"
            label={t("search_by_name")}
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onSearchClick();
                event.preventDefault();
              }
            }}
          />
        </FormControl>
        <FormControl sx={{ flexShrink: 0 }}>
          <Button
            sx={{ ml: 1 }}
            color="primary"
            size="large"
            onClick={onSearchClick}
          >
            <SearchIcon />
          </Button>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default React.memo(DistrictSearchBar);
