import { useEffect } from "react";
import i18n from "../i18n";
import { useDispatch, useSelector } from "react-redux";
const Languagehandler = () => {
  const userInfo = useSelector((state) => state?.userPreferenceSlice?.userInfo);

  useEffect(() => {
    if (userInfo) {
      i18n.changeLanguage(userInfo.preferred_language);
    }
  }, [userInfo]);
};

export default Languagehandler;
