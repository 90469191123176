import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Chip,
  Divider,
} from "@mui/material";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";
import { store } from "../redux/store";
import IwdSnackBar from "../components/IwdSnackBar";
import theme from "../theme";

const UserInfoItem = ({ title, value }) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
      >
        {title}:
      </Typography>
      <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
        {value ?? "-"}
      </Typography>
    </Box>
  );
};

function MyAccount() {
  const { t } = useTranslation();
  const [getPreference] = store.useLazyGetPreferenceQuery();
  const { userInfo } = useSelector((state) => state.userPreferenceSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) {
      getPreference();
    }
  }, []);

  useEffect(() => {
    dispatch(setBreadCrumbs("me"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);

  if (!userInfo) {
    return <Typography variant="h6">{t("loading")}</Typography>;
  }

  return (
    <Box sx={{ maxWidth: "1200px", margin: "auto", padding: 3 }}>
      <Typography sx={{ mb: 4 }} variant="h4" align="center" color="primary">
        {t("userInfo")}
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {/* User Information Cards */}
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Card
            sx={{
              boxShadow: 6,
              borderRadius: 3,
              padding: 4,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <CardContent>
              <UserInfoItem
                title={t("firstName")}
                value={userInfo.first_name}
              />
              <Divider sx={{ my: 2 }} />
              <UserInfoItem title={t("lastName")} value={userInfo.last_name} />
              <Divider sx={{ my: 2 }} />
              <UserInfoItem
                title={t("Email/Username")}
                value={userInfo.email}
              />
              <Divider sx={{ my: 2 }} />

              {/* Добавляем информацию о языке */}
              <UserInfoItem
                title={t("preferred_language")}
                value={userInfo.preferred_language}
              />
              <Divider sx={{ my: 2 }} />

              {/* Описание ролей */}
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: "bold", color: theme.palette.text.primary }}
                >
                  {t("roles")}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  {userInfo?.roles?.map((item) => (
                    <Chip
                      label={item}
                      sx={{
                        background: theme.palette.chip,
                        ml: 1,
                        color: "white",
                        borderRadius: 2,
                      }}
                    />
                  ))}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <IwdSnackBar />
    </Box>
  );
}

export default MyAccount;
