import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import validator from "@rjsf/validator-ajv6";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid2";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import OngoingPolicyIcon from "./Icons/OngoingPolicyIcon";
import PassedPolicyIcon from "./Icons/PassedPolicyIcon";
import InterruptedPolicyIcon from "./Icons/InterruptedPolicyIcon";
import WatingPolicyIcon from "./Icons/WatingPolicyIcon";
import { useNavigate } from "react-router-dom";
import theme from "../../theme";
import { useSelector, useDispatch } from "react-redux";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { policyActions } from "../../redux/slices/policySlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MeterDatePicker from "../form/formComponents/MeterDatePicker";
import UserWidget from "../users/UserWidget";
import DeleteIcon from "@mui/icons-material/Delete";
import { prepareParams } from "../../utils";
import { Link } from "react-router-dom";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import DeviceAutocomleteWidget from "./supply_widgets/DeviceAutocomleteWidget";
import { store } from "../../redux/store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import PoliciDatePicker from "./supply_widgets/PoliciDatePicker";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { resetUserShow } from "../../redux/slices/userSlice";

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        /*   if (element.name == "insertion_date") {
          return (
            <Grid key={element.id} size={12}>
              {element.content}
            </Grid>
          );
        } */

        return (
          <Grid key={element.id} alignItems="center" size={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const PolicyIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trashModal, setTrashModal] = useState(false);
  const [trashId, setTrashId] = useState(null);
  const haveRecords = useSelector((state) => state?.policySlice?.haveRecords);
  const policyIndex = useSelector((state) => state?.policySlice?.indexPolicy);
  const policyTrashId = useSelector((state) => state?.policySlice?.trashId);
  const [getIndexPolicy] = store.useLazyGetPolicyIndexQuery();
  const [deletePolicy, { isSuccess }] = store.useDeletePolicyMutation();
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [refreshParams, setRefreshParams] = useState(false);

  const perPage = 10;
  const [formData, setFormData] = useState({});

  const paramsList = {
    name: searchParams.get("name"),
    active: searchParams?.get("active"),
    aborted: searchParams.get("aborted"),
    start_date: searchParams.get("start_date"),
    end_date: searchParams.get("end_date"),
    user_id: searchParams.get("user_id"),
    device_id: searchParams.get("device_id"),
    page: page,
    per_page: perPage,
  };
  const styleSelector = (params) => {
    if (params?.row?.isActive && !params?.row?.interrupted) {
      return `super-app-theme--Filled custom-colored`;
    }
    if (params?.row?.interrupted) {
      return `super-app-theme--Orange custom-colored`;
    }
  };
  const isCurrentDateWithinRange = (startDate, endDate) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    return now >= start && now <= end;
  };
  const isDatePassed = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);

    return now > end;
  };

  const policyStatusSelector = (params) => {
    if (params?.value?.isActive && !params?.value?.interrupted) {
      return (
        <Tooltip title={t(`ongoing`)}>
          <Box>
            <OngoingPolicyIcon />
          </Box>
        </Tooltip>
      );
    } else if (params?.value?.interrupted) {
      return (
        <Tooltip title={t(`suspended`)}>
          <Box>
            <InterruptedPolicyIcon />
          </Box>
        </Tooltip>
      );
    } else if (params?.value?.isPassed) {
      return (
        <Tooltip title={t(`Completed`)}>
          <Box>
            <PassedPolicyIcon />
          </Box>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t(`upcoming`)}>
          <Box>
            <WatingPolicyIcon />
          </Box>
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    dispatch(setBreadCrumbs(t("supply_policies")));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);

  useEffect(() => {
    console.log("poliyfmdt", formData);
  }, [formData]);
  useEffect(() => {
    if (refreshParams) {
      getIndexPolicy({ ...paramsList });
      setRefreshParams(false);
    }
  }, [refreshParams, page]);
  useEffect(() => {
    getIndexPolicy({ ...paramsList });
  }, [page]);

  useEffect(() => {
    if (policyTrashId) {
      deletePolicy(policyTrashId);
      setTrashId(null);
      dispatch(policyActions.setTrashId(null));
    }
  }, [policyTrashId]);
  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("successfully_deleted")));
      getIndexPolicy({ ...paramsList });
    }
  }, [isSuccess]);

  useEffect(() => {
    console.log("policyIndex", policyIndex);
  }, [policyIndex]);

  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
        default: paramsList?.name ?? "",
      },
      user_id: {
        type: "string",
        title: t("user"),
      },
      active: {
        type: "string",
        title: t("active_policy"),
        enum: ["", "true", "false"],
        default: paramsList?.active ?? "",
      },
      aborted: {
        type: "string",
        title: t("aborted_policy"),
        enum: ["", "true", "false"],
        default: paramsList?.aborted ?? "",
      },
      insertion_date: {
        title: t("inserted_at"),
        type: "object",
        properties: {
          start_date: {
            type: "string",
            default: paramsList?.start_date ?? "",
          },
          end_date: {
            type: "string",
            default: paramsList?.end_date ?? "",
          },
        },
      },
      device_id: {
        type: "string",
      },
    },
  };
  const fields = {
    datepicker: PoliciDatePicker,
    user: UserWidget,
    device: DeviceAutocomleteWidget,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },

    user_id: {
      "ui:field": "user",
    },
    device_id: {
      "ui:field": "device",
    },
    "ui:submitButtonOptions": {
      submitText: t("search"),

      props: {
        className: "bntListSearch",
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };

  const onTrashClick = (id) => {
    setTrashId(id);
    setTrashModal(true);
  };

  useEffect(() => {
    dispatch(policyActions.resetPolicyShow());
    dispatch(resetUserShow());
  }, []);

  const columns = [
    {
      field: "state",
      headerName: t("state"),
      editable: false,
      flex: 0.4,
      width: 20,
      renderCell: (params) => {
        return (
          <Box display={"flex"} width={"100%"} justifyContent={"flex-start"}>
            {policyStatusSelector(params)}
          </Box>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },
    {
      field: "name",
      headerName: t("name"),
      editable: false,
      flex: 2,
    },

    {
      field: "start_date",
      headerName: t("start_date"),
      width: 90,
      sortable: false,

      flex: 1,
    },
    {
      field: "end_date",
      headerName: t("end_date"),
      width: 90,
      sortable: false,

      flex: 1,
    },
    {
      field: "interrupted",
      headerName: t("interrupted"),
      width: 90,
      sortable: false,

      flex: 1,
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Link to={`/supply_policies/${params.value.id}`}>
              <VisibilityIcon sx={{ mt: 1, fill: theme.palette.navIcon }} />
            </Link>
            <IwdAuthWrapper
              children={
                <Tooltip
                  title={
                    (params?.value?.isActive || params?.value?.isPassed) &&
                    t("cant_delete_active_policy")
                  }
                >
                  <Box>
                    <IconButton
                      disabled={
                        params?.value?.isActive || params?.value?.isPassed
                      }
                      onClick={() => onTrashClick(params?.value.id)}
                    >
                      <DeleteIcon
                        sx={{
                          fill:
                            !params?.value?.isActive && !params?.value?.isPassed
                              ? theme.palette.error.main
                              : "#616161",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Tooltip>
              }
              section={"delete_meter"}
            />
          </Box>
        );
      },
    },
  ];
  const sortedPolicyIndex =
    policyIndex?.length > 0 &&
    [...policyIndex].sort(
      (a, b) => new Date(b.start_date) - new Date(a.start_date)
    );
  const rows =
    sortedPolicyIndex?.length > 0 &&
    sortedPolicyIndex?.map((item) => ({
      id: item?.id,
      name: item?.name,
      state: {
        isActive: isCurrentDateWithinRange(item?.start_date, item?.end_date),
        isPassed: isDatePassed(item?.end_date),
        interrupted: item?.interrupted
          ? t("dt", {
              val: new Date(item?.interrupted),
            })
          : null,
      },
      start_date: t("dt", {
        val: new Date(item?.start_date),
      }),
      end_date: t("dt", {
        val: new Date(item?.end_date),
      }),
      interrupted: item?.interrupted
        ? t("dt", {
            val: new Date(item?.interrupted),
          })
        : null,
      isActive: isCurrentDateWithinRange(item?.start_date, item?.end_date),
      actions: {
        id: item?.id,
        isActive: isCurrentDateWithinRange(item?.start_date, item?.end_date),
        isPassed: isDatePassed(item?.end_date),
      },
    }));
  const onSubmitClick = (obj) => {
    obj["page"] = page;
    obj["per_page"] = perPage;

    const params = prepareParams(obj);
    navigate({
      pathname: "/supply_policies",
      search: params.toString(),
    });

    setPage(1);
    setRefreshParams(true);
  };

  useEffect(() => {
    console.log("policyIndex", sortedPolicyIndex);
  }, [sortedPolicyIndex]);
  return (
    <>
      <DeleteConfirmModal
        trashModal={trashModal}
        setTrashModal={setTrashModal}
        text={t("policy_delete_text")}
        title={t("policy_delete_title")}
        deleteFunc={() => policyActions.setTrashId(trashId)}
      />
      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={"flex-end"}
        sx={{ mb: 2 }}
      >
        <Button onClick={() => navigate("/supply_policies/create")}>
          {t("create_new_policy")}
        </Button>
      </Box>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fill: theme.palette.navIcon }} />}
        >
          {t("search_form")}
        </AccordionSummary>
        <AccordionDetails>
          <Form
            schema={schema}
            fields={fields}
            formData={formData}
            onChange={(changeEvent) => setFormData(changeEvent.formData)}
            onSubmit={(values) => onSubmitClick(values.formData)}
            validator={validator}
            uiSchema={uiSchema}
            /*     customValidate={customValidate} */
            showErrorList={false}
            noHtml5Validate
          />
        </AccordionDetails>
      </Accordion>
      <Box sx={{ mb: 3 }}>
        <IwdDataGrid
          columns={columns}
          perPage={perPage}
          setPage={setPage}
          rows={rows}
          page={page}
          records={haveRecords}
          infiniteMode={true}
          recordsFn={policyActions.setAllPolicyRecors}
          sort={false}
          paginationMode={"server"}
          height={545}
          tableName={"supply_policy_index"}
        />
      </Box>
    </>
  );
};
export default PolicyIndex;
