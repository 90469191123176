import * as React from "react";

const OpenValve = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width="35"
    height="35"
    fill="#000"
    version="1.1"
    viewBox="0 0 485 485"
  >
    <g id="SVGRepo_iconCarrier">
      <g id="XMLID_33_">
        <g id="XMLID_63_">
          <path fill="#31C0D8" d="M182.5 292.5h120v90h-120z"></path>
          <path
            fill="#AFB6BB"
            d="M242.5 102.5c0 1.7-.2 3.4-.5 5h1c-.3-1.6-.5-3.3-.5-5 0-16.5 13.5-30 30-30h20c16.5 0 30 13.5 30 30s-13.5 30-30 30c0 0-20 0-25 5v65h-50v-65c-5-5-25-5-25-5-16.5 0-30-13.5-30-30s13.5-30 30-30h20c16.5 0 30 13.5 30 30"
          ></path>
          <path fill="#E7ECED" d="M162.5 202.5h160v50h-160z"></path>
          <path fill="#AFB6BB" d="M7.5 252.5h55v130h-55z"></path>
          <path
            fill="#798084"
            d="M422.5 252.5v150c0 5.5-4.5 10-10 10h-40c-5.5 0-10-4.5-10-10v-170c0-5.5 4.5-10 10-10h40c5.5 0 10 4.5 10 10zM122.5 382.5v20c0 5.5-4.5 10-10 10h-40c-5.5 0-10-4.5-10-10v-170c0-5.5 4.5-10 10-10h40c5.5 0 10 4.5 10 10v150"
          ></path>
          <path
            fill="#AFB6BB"
            d="M362.5 252.5v130h-60v-90h-120v90h-60v-130h200zM422.5 252.5h55v130h-55z"
          ></path>
          <path d="M485 245h-55v-12.5c0-9.649-7.851-17.5-17.5-17.5h-40c-9.649 0-17.5 7.851-17.5 17.5V245h-25v-50h-55v-53.046c3.818-1.097 11.004-1.947 17.5-1.954 20.678 0 37.5-16.822 37.5-37.5S313.178 65 292.5 65h-20c-12.253 0-23.153 5.907-30 15.023C235.653 70.907 224.753 65 212.5 65h-20c-20.678 0-37.5 16.822-37.5 37.5s16.822 37.5 37.5 37.5c6.491 0 13.679.851 17.5 1.951V195h-55v50h-25v-12.5c0-9.649-7.851-17.5-17.5-17.5h-40c-9.649 0-17.5 7.851-17.5 17.5V245H0v145h55v12.5c0 9.649 7.851 17.5 17.5 17.5h40c9.649 0 17.5-7.851 17.5-17.5V390h225v12.5c0 9.649 7.851 17.5 17.5 17.5h40c9.649 0 17.5-7.851 17.5-17.5V390h55zM192.5 125c-12.407 0-22.5-10.094-22.5-22.5S180.093 80 192.5 80h20c12.407 0 22.5 10.094 22.5 22.5 0 1.085-.125 2.303-.372 3.618L232.964 115h19.072l-1.665-8.882c-.247-1.316-.372-2.533-.372-3.618 0-12.406 10.093-22.5 22.5-22.5h20c12.407 0 22.5 10.094 22.5 22.5s-10.093 22.5-22.5 22.5c-6.947 0-23.808.701-30.303 7.196L260 134.394V195h-35v-60.606l-2.197-2.197C216.308 125.701 199.447 125 192.5 125M170 210h145v35H170zM15 375V260h40v115zm100 27.5c0 1.355-1.145 2.5-2.5 2.5h-40c-1.355 0-2.5-1.145-2.5-2.5v-170c0-1.355 1.145-2.5 2.5-2.5h40c1.355 0 2.5 1.145 2.5 2.5zm75-67.5h87.5v-15H190v-20h105v75H190zm120 40v-90H175v90h-45V260h225v115zm105 27.5c0 1.355-1.145 2.5-2.5 2.5h-40c-1.355 0-2.5-1.145-2.5-2.5v-170c0-1.355 1.145-2.5 2.5-2.5h40c1.355 0 2.5 1.145 2.5 2.5zm55-27.5h-40V260h40z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default OpenValve;
