import React, { useEffect, useState } from "react";
import AttributionIcon from "@mui/icons-material/Attribution";
import { Box, Card, CardContent, Typography, Chip } from "@mui/material";
import { store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import theme from "../../../theme";
import Skeleton from "@mui/material/Skeleton";
import { maxWidth } from "@mui/system";
const UserTooltipShow = ({ id }) => {
  const { t } = useTranslation();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataShowQuery();
  const [user, setUser] = useState(null);
  const handleMouseEnter = () => {
    setIsTooltipVisible(true); // Показать тултип
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false); // Скрыть тултип
  };

  useEffect(() => {
    if (!user && isTooltipVisible && id) {
      trigger(`user/${id}`)
        ?.unwrap()
        ?.then((data) => setUser(data?.data?.attributes))
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  }, [user, isTooltipVisible, id]);

  useEffect(() => {
    console.log("useryaka", user);
  }, [user]);

  return (
    <Box
      style={{
        display: "inline-block",
        position: "relative",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <AttributionIcon sx={{ color: "grey", cursor: "pointer" }} />

      {/* Кастомный тултип */}
      {isTooltipVisible && user && (
        <Box
          style={{
            position: "absolute",
            top: "100%", // Позиционируем тултип снизу от иконки
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#333",
            color: "#fff",
            padding: "8px",
            borderRadius: "5px",
            whiteSpace: "nowrap", // Чтобы текст не переносился
            zIndex: 10, // Чтобы тултип был поверх других элементов
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography>{`${t("user")}: ${user?.first_name} ${
            user?.last_name
          }`}</Typography>
          <Box display={"flex"} sx={{ mt: 2 }} width={"100%"}>
            <Typography>{t("roles")}: </Typography>
            {user?.roles?.map((item) => (
              <Chip
                label={item}
                sx={{ background: theme.palette.navIcon, ml: 1 }}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserTooltipShow;
