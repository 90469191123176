import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { setUserUpdateFields } from "../../redux/slices/userSlice";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from "@mui/icons-material/Done";
import BlockAutocomlete from "../widgets/BlockAutocomlete";
import { store } from "../../redux/store";
import UserGroupsWidget from "./UserGroupsWidget";
import { useEffect } from "react";
import BlockGroups from "../condominium/BlockGroups";
import { setCondomiumShow } from "../../redux/slices/condominiumSlice";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import theme from "../../theme";
import { Link } from "react-router-dom";
import DeleteButton from "../../utils/DeleteButton";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { setUserName } from "../../redux/slices/breadCrumbMeterSlice";
import TransferList from "../TransferList";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

import { unsetUserRoles } from "../../redux/slices/userSlice";

import TrashModal from "../TrashModal";
import { setMessage } from "../../redux/slices/snackbarSlice";
const UserShow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getCondominumList] = store.useLazyGetCondominiumListQuery();
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const condominumList = useSelector(
    (state) => state?.userSlice?.condominumList
  );
  const userRoles = useSelector((state) => state?.userSlice?.userRoles);
  const allRoles = useSelector((state) => state?.aegisSlice?.roles);
  const [deleteUser, { isSuccess: deleteSuccess }] =
    store.useDeleteUserMutation();
  const [getUserShow, { isSuccess: userSuccess }] =
    store.useLazyGetUserShowQuery();
  const [getGrant] = store.useLazyGetGrantQuery();
  const [getAllRoles] = store.useLazyGetAllrolesQuery();
  const { id } = useParams();
  const grantId = useSelector((state) => state?.userSlice?.grantId);
  const { t } = useTranslation();
  const userShow = useSelector((state) => state?.userSlice?.userShow);
  const data = userShow?.attributes;
  useEffect(() => {
    getUserShow(id);
    getGrant(id);
    getAllRoles();
  }, []);
  useEffect(() => {
    getCondominumList(id);
    dispatch(setCondomiumShow(null));
  }, []);
  useEffect(() => {
    if (userShow) {
      const user = userShow?.attributes;
      const fields = {
        preferred_language:
          user?.preferred_language && user?.preferred_language,
        first_name: user?.first_name,
        last_name: user?.last_name,
        /*    nick_name: user?.nick_name,
        display_name: user?.display_name, */
      };
      dispatch(setUserUpdateFields(fields));
    }
  }, [userShow]);
  useEffect(() => {
    if (userShow) {
      dispatch(
        setBreadCrumbs({
          id: `${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [userShow]);
  const userStateChek = (state) => {
    switch (state) {
      case "USER_STATE_UNSPECIFIED":
        return t("state_unspecified");
      case "USER_STATE_ACTIVE":
        return t("state_active");
      case "USER_STATE_INACTIVE":
        return t("state_inactive");
      case "USER_STATE_DELETED":
        return t("state_deleted");
      case "USER_STATE_LOCKED":
        return t("state_locked");
      case "USER_STATE_SUSPEND":
        return t("state_suspend");
      case "USER_STATE_INITIAL":
        return t("state_initial");
    }
  };
  const onTrashClick = () => {
    dispatch(opendModal());
  };
  useEffect(() => {
    if (deleteConfirmed) {
      deleteUser(id);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  useEffect(() => {
    if (deleteSuccess) {
      navigate("/users");
      dispatch(setMessage(t("successfully_deleted")));
    }
  }, [deleteSuccess]);
  useEffect(() => {
    return () => {
      dispatch(unsetUserRoles());
      /*       dispatch(setUserName(null)); */
    };
  }, []);
  useEffect(() => {
    if (userShow) {
      dispatch(
        setUserName(
          `${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`
        )
      );
    }
  }, [userShow]);

  return (
    <>
      {userSuccess && (
        <>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={{ mb: 4 }} variant="h4">
              {`${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`}
            </Typography>
            <Box>
              <Button
                sx={{ mr: 2 }}
                onClick={() => navigate("edit")}
                variant={window.BUTTON_TYPE}
              >
                {t("update")}
              </Button>
              <DeleteButton name={t("delete")} onClick={onTrashClick} />
            </Box>
          </Box>

          <Box>
            <TrashModal
              title={t("delete_user_title")}
              text={t("delete_user_text")}
            />
            <Grid
              container
              alignItems="center"
              rowSpacing={5}
              direction="row"
              spacing={5}
              xs={12}
            >
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("id")}:
                </Typography>
                <Typography variant="subtitle1">
                  {userShow?.id ?? "--"}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("update_date")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.change_date
                    ? t("dt", { val: new Date(data?.change_date) })
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("creation_date")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.creation_date
                    ? t("dt", { val: new Date(data?.creation_date) })
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("Email/Username")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.email ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("first_name")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.first_name ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("last_name")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.last_name ?? "--"}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("is_email_verified")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.is_email_verified === false ? (
                    <CloseIcon sx={{ fill: "red" }} />
                  ) : (
                    <DoneIcon sx={{ fill: "green" }} />
                  )}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("otp")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.otp === false ? (
                    <CloseIcon sx={{ fill: "red" }} />
                  ) : (
                    <DoneIcon sx={{ fill: "green" }} />
                  )}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("state")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.state ? userStateChek(data?.state) : "--"}
                </Typography>
              </Grid>

              {condominumList?.length > 0 &&
                userShow?.attributes?.roles?.includes(
                  "building_automation"
                ) && (
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {t("associated_condominiums")}:
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      {condominumList?.map((item) => (
                        <Link to={`/condominiums/${item?.id}`} key={item?.id}>
                          <Chip
                            label={item?.attributes?.fields?.name}
                            sx={{
                              ml: 1,
                              mt: 1,
                            }}
                          />
                        </Link>
                      ))}
                    </Box>
                  </Grid>
                )}
              <IwdAuthWrapper
                children={
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {userRoles?.length > 0 ? (
                          <Box display="flex">
                            <Typography sx={{ mt: 1 }}>
                              {t("user_roles") + ": "}
                            </Typography>
                            <Box>
                              {userRoles?.map((item) => (
                                <Chip label={item} sx={{ ml: 1 }} />
                              ))}
                            </Box>
                          </Box>
                        ) : (
                          t("no_roles")
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        {userRoles && allRoles && (
                          <TransferList
                            userRoles={userRoles}
                            allRoles={allRoles?.filter(
                              (item) => !userRoles.includes(item)
                            )}
                            userId={id}
                            grantId={grantId}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {userShow?.attributes?.roles?.includes(
                      "building_automation"
                    ) && (
                      <IwdAuthWrapper
                        section={"use_cond_assoc"}
                        children={
                          <Grid item xs={12} sx={{ mt: 5 }}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                {t("assoc_block")}
                              </AccordionSummary>
                              <AccordionDetails>
                                <BlockAutocomlete userId={id} />
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        }
                      />
                    )}
                    <IwdAuthWrapper
                      section={"usershow_usergroups"}
                      children={
                        <Grid item xs={12} sx={{ mt: 5 }}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              {t("user_groups")}
                            </AccordionSummary>
                            <AccordionDetails>
                              <UserGroupsWidget />
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      }
                    />
                  </Grid>
                }
                section={"roles_transfer"}
              />
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
export default UserShow;
