import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import MenuList from "@mui/material/MenuList";
import { setErrors } from "../../../redux/slices/errorSlice";
import { Box } from "@mui/material";
import { setGatewayExportStatus } from "../../../redux/slices/gatewaySlice";
import { store } from "../../../redux/store";
import { useSearchParams } from "react-router-dom";
import ModalDialog from "../../meters/ModalDialog";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../redux/slices/iwdWebSocketSlice";
import { loadingOn, loadingOff } from "../../../redux/slices/loadingSlice";
/* const url = "http://192.168.1.94:4006/api/v1/altior/device/export/download?";  344*/

const GatewayExportButton = ({ styleProp }) => {
  const url = `${window.BASE_URL}/api/v1/altior/download/device/gateway?`;
  const bearer = localStorage.getItem("user_token");

  const [localConnect, setLocalConnect] = useState(false);
  const [readyToConnect, setReadyToConnect] = useState(false);
  const [loading, setLoading] = useState(false);
  /*   const loading = useSelector((state) => state?.loadingSlice?.loading); */
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState(null);
  const exportStatus = useSelector(
    (state) => state?.gatewaySlice?.exportStatus
  );
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [uuid, setUUID] = useState(uuidv4());
  const [startDownload, setStartDownload] = useState(false);
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  useEffect(() => {}, [url]);
  const [
    startExportDevice,
    {
      isLoading: isDeviceExportLoading,
      isSuccess: isDeviceExportSuccess,
      isError: isDeviceExportError,
      data: resultStartExport,
      requestId,
    },
  ] = store.useLazyGatewayStartExportQuery();
  const [
    downloadDevice,
    {
      isSuccess: isDeviceDownloadSuccess,
      isError: isDeviceDownloadError,
      data: resultStartDownload,
      reset: resetStartDownload,
    },
  ] = store.useGatewayDownloadMutation();
  const {
    isWSConnected,
    subscribedChannels,
    data,
    error,
    channels,
    cashedData,
  } = useSelector((state) => state.iwdWebSocketSlice);
  const { t } = useTranslation();
  const options = [`${t("export")} XLSX` /* "Export Trilliant" */];
  useEffect(() => {
    /*     dispatch(loadingOff()); */
    setLoading(false);
  }, [isDeviceDownloadSuccess, isDeviceDownloadError]);

  useEffect(() => {
    if (isWSConnected && !channels["export_status"] && readyToConnect) {
      dispatch(
        actions.joinChannel({
          name: "export_status",
          params: { uuid: uuid },
        })
      );
    }
  }, [isWSConnected, readyToConnect]);

  useEffect(() => {
    if (
      data &&
      isWSConnected &&
      ((data?.data_update?.[0]?.uuid == uuid &&
        data?.data_update?.[0]?.status == "ready") ||
        (cashedData?.[0]?.uuid == uuid && cashedData?.[0]?.status == "ready"))
    ) {
      const copySearchParams = new URLSearchParams(searchParams);
      copySearchParams.append("download_type", "excel");
      copySearchParams.append("uuid", uuid);
      copySearchParams.append("token", bearer);
      window.open(`${url}${copySearchParams.toString()}`, "_blank");

      /*  dispatch(loadingOff()); */
      setLoading(false);
    }
  }, [data, data?.data_update, cashedData]);

  useEffect(() => {
    if (
      isWSConnected &&
      channels["export_status"] &&
      (!subscribedChannels["export_status"] ||
        !subscribedChannels["export_status"]["data_update"]) &&
      readyToConnect
    ) {
      dispatch(
        actions.subscribeChannel({
          channelName: "export_status",
          eventName: "data_update",
        })
      );

      if (startDownload) {
        const copySearchParams = new URLSearchParams(searchParams);

        copySearchParams.append("download_type", "excel");
        copySearchParams.append("uuid", uuid);
        startExportDevice(copySearchParams);
        setStartDownload(false);
      }
    }
  }, [channels]);

  useEffect(() => {
    if (!isDeviceExportLoading) {
      switch (exportStatus) {
        case "creating":
          /*  dispatch(loadingOn()); */
          setLoading(true);
          break;
        case "already_created":
          const copySearchParams = new URLSearchParams(searchParams);
          copySearchParams.append("download_type", "excel");
          copySearchParams.append("uuid", uuid);
          copySearchParams.append("token", bearer);

          window.open(`${url}${copySearchParams.toString()}`, "_blank");
          dispatch(setGatewayExportStatus(null));
          /* dispatch(loadingOff()); */
          setLoading(false);
          break;
        default:
          break;
      }
    }
    resetStartDownload();
  }, [isDeviceExportSuccess, isDeviceExportLoading, requestId, exportStatus]);

  useEffect(() => {
    setStatus(resultStartExport);
  }, [resultStartExport]);
  useEffect(() => {}, [status]);

  const handleClick = () => {
    setReadyToConnect(true);
    console.info(`You clicked ${options[selectedIndex]}`);
    /*  dispatch(loadingOn()); */
    setLoading(true);
    switch (selectedIndex) {
      case 0:
        if (!isWSConnected) {
          dispatch(actions.connect());
          setStartDownload(true);
          setLocalConnect(true);
        } else {
          const copySearchParams = new URLSearchParams(searchParams);
          copySearchParams.append("download_type", "excel");
          copySearchParams.append("uuid", uuid);
          resetStartDownload();
          startExportDevice(copySearchParams);
        }

        break;

      default:
        alert("Nothing to do");
        break;
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  useEffect(() => {
    if (error) {
      dispatch(setErrors({ error: error }));
    }
  }, [error]);
  useEffect(() => {
    if (cashedData?.length > 0) {
      dispatch(actions.setData(cashedData));
    }
  }, [cashedData]);
  useEffect(() => {
    return () => {
      if (channels["export_status"] && readyToConnect) {
        dispatch(actions.cleanData("data_update"));

        dispatch(actions.leaveChannel("export_status"));
      }
    };
  }, [channels]);

  return (
    <>
      {bearer && (
        <>
          <ModalDialog open={loading} title={t("note")} cancell={false}>
            <Box display={"flex"}>
              {t("exporting_data")}
              <Box sx={{ ml: 1 }}>
                <CircularProgress size={20} />
              </Box>
            </Box>
          </ModalDialog>
          <Box sx={styleProp}>
            <ButtonGroup
              variant={window?.THEME?.BUTTON_TYPE}
              ref={anchorRef}
              aria-label="split button"
            >
              <Button onClick={handleClick}>{options[selectedIndex]}</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {options?.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </>
      )}
    </>
  );
};

export default GatewayExportButton;
