import IwdDataGrid from "../../../pages/IwdDataGrid";
import { Box, Card, CardContent, Typography, Chip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { store } from "../../../redux/store";
import { Link, useParams } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Tooltip } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import SendIcon from "@mui/icons-material/Send";
import WarningIcon from "@mui/icons-material/Warning";
import { policyActions } from "../../../redux/slices/policySlice";
import Person4Icon from "@mui/icons-material/Person4";
import UserTooltipShow from "./UserTooltipShow";
import { useState, useEffect } from "react";
import theme from "../../../theme";
const DevicrPolicyEvents = ({ page, setPage, per_page }) => {
  const { id, deviceId } = useParams();
  const events = useSelector((state) => state?.policySlice?.devicePolicyEvents);
  const { t } = useTranslation();
  const eventsHaveRecords = useSelector(
    (state) => state?.policySlice?.eventsHaveRecords
  );
  const [getDevicePolicyEvents] = store.useLazyGetDevicePolicyEventsQuery();

  function convertCubicMetersToLiters(cubicMeters) {
    return +cubicMeters * 1000;
  }

  useEffect(() => {
    if (id && deviceId) {
      getDevicePolicyEvents({
        policyId: id,
        devicePolicyId: deviceId,
        params: {
          page: page,
          per_page: per_page,
        },
      });
    }
  }, [id, deviceId, page]);

  const unitSelector = (unit) => {
    if (unit == "m^3") {
      return "m³";
    } else {
      return unit;
    }
  };

  const actionIconSelector = (action) => {
    switch (action) {
      case "send_command":
        return <SendIcon sx={{ color: "grey" }} />;
      case "user_activity":
        return <SettingsAccessibilityIcon sx={{ color: "grey" }} />;
      case "anomaly":
        return <WarningIcon sx={{ color: "grey" }} />;
      case "new_consumption":
        return <ReceiptIcon sx={{ color: "grey" }} />;
      case "update":
        return <AutorenewIcon sx={{ color: "grey" }} />;
      case "manual_action":
        return <WavingHandIcon sx={{ color: "grey" }} />;
      case "user_external_activity":
        return <Person4Icon sx={{ color: "grey" }} />;
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },
    {
      field: "action",
      headerName: t("action"),
      editable: false,
      flex: 0.4,
      renderCell: (params) => {
        return (
          <Box display={"flex"} justifyContent={"flex-start"}>
            <Tooltip title={t(`${params?.value}`)}>
              {actionIconSelector(params?.value)}
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "details",
      headerName: t("details"),
      editable: false,
      flex: 1,
      renderCell: (params) => {
        const replaceUnderscoresWithSpaces = (str) => str.replace(/_/g, " ");

        // Условие для действия "send_command"
        if (
          params?.value?.action === "send_command" ||
          params?.value?.action === "manual_action"
        ) {
          const meta = params?.value?.meta;

          // Отображаем command_name
          const commandName = meta?.command_name ? (
            <Typography sx={{ mb: 1 }}>
              {replaceUnderscoresWithSpaces(meta?.command_name)}
            </Typography>
          ) : null;

          // Если есть объект params внутри meta, отображаем его содержимое
          if (meta?.params && typeof meta?.params === "object") {
            const paramsContent = Object.entries(meta?.params).map(
              ([key, value]) => (
                <Typography key={key} sx={{ mb: 0.5 }}>
                  <strong>{replaceUnderscoresWithSpaces(key)}:</strong> {value}
                </Typography>
              )
            );

            return (
              <Box>
                {commandName}
                {paramsContent}
              </Box>
            );
          }

          return <Box>{commandName}</Box>;
        }

        if (
          params?.value?.action === "user_external_activity" ||
          params?.value?.action === "user_activity"
        ) {
          return (
            <Box display={"flex"}>
              <Typography>
                {replaceUnderscoresWithSpaces(params?.value?.meta?.type)}
              </Typography>
              <Box sx={{ ml: 2 }}>
                <UserTooltipShow id={params?.value?.meta?.user_id} />
              </Box>
            </Box>
          );
        }

        if (params?.value?.action === "update") {
          const newState = params?.value?.meta?.new_state || {};
          const oldState = params?.value?.meta?.old_state || {};

          const oldValue = oldState["valve_state"];
          const newValue = newState["valve_state"];

          return (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" sx={{ fontWeight: "bold", mr: 1 }}>
                {t("valve_state")}
              </Typography>
              {oldValue !== newValue ? (
                <>
                  <Typography
                    sx={{
                      textDecoration: "line-through",
                      color: "text.secondary",
                    }}
                  >
                    {replaceUnderscoresWithSpaces(oldValue)}
                  </Typography>
                  <Typography sx={{ mx: 0.5, color: "success.main" }}>
                    →
                  </Typography>
                  <Typography sx={{ color: "success.main" }}>
                    {replaceUnderscoresWithSpaces(newValue)}
                  </Typography>
                </>
              ) : (
                <Typography>
                  {replaceUnderscoresWithSpaces(newValue)}
                </Typography>
              )}
            </Box>
          );
        }
        if (params?.value?.action == "anomaly") {
          return <Typography>{t(`${params?.value?.meta?.type}`)}</Typography>;
        }
        if (params?.value?.action === "new_consumption") {
          return (
            <Typography>
              {`${t("number", {
                val: params?.value?.meta?.consumption?.value,
              })} ${unitSelector(
                params?.value?.meta?.consumption?.meta?.unit
              )} ${
                params?.value?.meta?.consumption?.meta?.unit !== "l" &&
                params?.value?.meta?.consumption?.meta?.unit !== "L"
                  ? `(${convertCubicMetersToLiters(
                      t("number", {
                        val: params?.value?.meta?.consumption?.value,
                      })
                    )} L)`
                  : ""
              }, ${t("valve_state")} : ${
                params?.value?.meta?.consumption?.meta?.valve_state
              }`}
            </Typography>
          );
        }

        return null; // Для других действий возвращаем null
      },
    },
    {
      field: "inserted_at",
      headerName: t("event_date"),
      width: 90,
      sortable: false,

      flex: 1,
    },
  ];

  const rows = events?.map((item) => ({
    id: item?.id,
    action: item?.action,
    details: {
      action: item?.action,
      meta: item?.meta,
    },
    inserted_at: t("dt", {
      val: new Date(
        item?.action === "new_consumption"
          ? item?.meta?.consumption?.date
          : item?.inserted_at
      ),
    }),
    updated_at: t("dt", {
      val: new Date(item?.updated_at),
    }),
  }));

  return (
    <Box sx={{ mb: 3 }}>
      <IwdDataGrid
        columns={columns}
        perPage={per_page}
        setPage={setPage}
        rows={rows}
        page={page}
        records={eventsHaveRecords}
        infiniteMode={true}
        recordsFn={policyActions.setEventsRecords}
        sort={false}
        paginationMode={"server"}
        height={545}
        /*   styles={styleSelector} */
        tableName={"device_policy_events"}
      />
    </Box>
  );
};
export default DevicrPolicyEvents;
