// ErrorDisplay.jsx

import React, { useEffect, useState, useLayoutEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clearErrors } from "../redux/slices/errorSlice";

import { errorRedirectOff } from "../redux/slices/errorSlice";
import { useLocation } from "react-router-dom";

const ErrorDisplay = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const blockRedirect = useSelector(
    (state) => state?.errorSlice?.blockRedirect
  );
  const showError = useSelector((state) => state?.errorSlice?.showError);
  const errorType = useSelector((state) => state?.errorSlice?.errorType);
  const prevLocation = useSelector((state) => state?.utilsSlice?.prevLocation);
  const errorRedirect = useSelector(
    (state) => state?.errorSlice?.errorRedirect
  );
  const location = useLocation();
  const currentPath = location.pathname;
  const errorData = {
    errors: useSelector((state) => state?.errorSlice?.errors),
    errorTitle: useSelector((state) => state?.errorSlice?.errorTitle),
    errorStatus: useSelector((state) => state?.errorSlice?.errorStatus),
    errData: useSelector((state) => state?.errorSlice?.data),
  };

  const customErrors = (err) => {
    switch (err) {
      case "TypeError: Failed to fetch":
        return t("failed_fetch");
      case "detail: Unprocessable Entity":
        return t("no_entity");
      case "SyntaxError: Unexpected token":
        return t("unexpected_token");
      case "detail: Not Found":
        return t("not_found_err");
      case "detail: [object Object]":
        return t("not_found_err");
      default:
        return err;
    }
  };

  const handleErrors = (errorData) => {
    let arrErrors;

    if (errorData?.errData?.length > 0) {
      if ((arrErrors = errorData?.errData[0] === "pass_err")) {
        arrErrors = [t("pass_err")];
      } else if (errorData?.errData[0] === "not_found_user") {
        arrErrors = [t("not_found_user")];
      } else if (
        errorData?.errData[0] ==
        "Device is present in other policy in the same period"
      ) {
        arrErrors = [t("device_already_in_policy")];
      } else {
        arrErrors = errorData?.errData;
      }
    } else {
      if (errorData?.errorStatus == 500) {
        arrErrors = ["Internal server error"];
      }
      if (typeof errorData?.errors === "string") {
        if (errorData?.errors.includes("SyntaxError: Unexpected token")) {
          arrErrors = ["SyntaxError: Unexpected token"];
        } else {
          arrErrors = [errorData?.errors];
        }
      } else {
        arrErrors = errorData?.errors;
      }
    }
    return arrErrors?.map((item, i) => (
      <Typography>{customErrors(item)}</Typography>
    ));
  };
  useLayoutEffect(() => {
    if (
      !blockRedirect &&
      errorType === "show" &&
      window.history.length > 1 &&
      errorRedirect &&
      prevLocation !== "/login"
    ) {
      navigate(prevLocation);

      dispatch(errorRedirectOff());
    }
  }, [errorType, errorRedirect]);

  // Use useEffect to scroll to top on component mount
  useEffect(() => {
    if (errorRedirect) {
      window.scrollTo(0, 0);
    }
  }, [errorRedirect]);

  const onClearClick = () => {
    dispatch(clearErrors());
    if (window.history.length <= 1) {
      window.close();
    }
  };

  useEffect(() => {}, [currentPath]);

  return (
    <>
      {showError && (
        <Box
          sx={{
            marginTop: 2,
            position: "relative",
            width: "100%",
            maxWidth: "100%",
            padding: "16px",
            backgroundColor: "#f44336",
            color: "#fff",
            borderRadius: "4px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            marginBottom: "16px",
            overflowWrap: "break-word",
            wordWrap: "break-word",
            overflow: "hidden",
            zIndex: 9999,
          }}
        >
          <IconButton
            onClick={onClearClick}
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              paddingRight: "40px",
            }}
          >
            {handleErrors(errorData)}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ErrorDisplay;
