import * as React from "react";

const InterruptedPolicyIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="icon"
    width="16"
    height="16"
    fill="#d32f2f"
    viewBox="0 0 32 32"
  >
    <g id="SVGRepo_iconCarrier">
      <path d="M30 24a6 6 0 1 0-6 6 6.007 6.007 0 0 0 6-6m-2 0a3.95 3.95 0 0 1-.567 2.019l-5.452-5.452A3.95 3.95 0 0 1 24 20a4.005 4.005 0 0 1 4 4m-8 0a3.95 3.95 0 0 1 .567-2.019l5.452 5.452A3.95 3.95 0 0 1 24 28a4.005 4.005 0 0 1-4-4"></path>
      <path d="M14 2a12 12 0 1 0 2 23.82v-2.022A10 10 0 1 1 14 4v10l4.343 4.343A7.98 7.98 0 0 1 24 16h1.82A11.93 11.93 0 0 0 14 2"></path>
      <path
        id="_Transparent_Rectangle_"
        fill="none"
        d="M0 0h32v32H0z"
        data-name="&lt;Transparent Rectangle&gt;"
      ></path>
    </g>
  </svg>
);

export default InterruptedPolicyIcon;
