import { createSlice } from "@reduxjs/toolkit";

import { store } from "../store";
const initialState = {
  indexPolicy: null,
  policyShow: null,
  haveRecords: "waiting",
  trashId: null,
  devicePolicyIndex: null,
  devicePolicyShow: null,
  devicePolicyEvents: null,
  eventsHaveRecords: "waiting",
};

const policySlice = createSlice({
  name: "policySlice",
  initialState,
  reducers: {
    setAllPolicyRecors(state, action) {
      state.haveRecords = action.payload;
    },
    setEventsRecords(state, action) {
      state.eventsHaveRecords = action.payload;
    },
    setTrashId(state, action) {
      state.trashId = action.payload;
    },
    resetPolicyShow(state, action) {
      state.policyShow = null;
      state.devicePolicyIndex = null;
      state.devicePolicyShow = null;
      state.devicePolicyEvents = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.policyEndpoints.getPolicyIndex.matchFulfilled,
      (state, action) => {
        state.indexPolicy = action.payload.data.attributes.data;
        if (action.payload.data.attributes.data.length > 0) {
          state.haveRecords = "havedata";
        } else {
          state.haveRecords = "nodata";
        }
      }
    );
    builder.addMatcher(
      store.policyEndpoints.getPolicyShow.matchFulfilled,
      (state, action) => {
        state.policyShow = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.policyEndpoints.getDevicePolicyIndex.matchFulfilled,
      (state, action) => {
        state.devicePolicyIndex = action.payload.data.attributes?.data;
      }
    );
    builder.addMatcher(
      store.policyEndpoints.getDevicePolicyShow.matchFulfilled,
      (state, action) => {
        state.devicePolicyShow = action.payload.data.attributes?.data;
      }
    );
    builder.addMatcher(
      store.policyEndpoints.getDevicePolicyEvents.matchFulfilled,
      (state, action) => {
        state.devicePolicyEvents = action.payload.data.attributes?.data;
        if (action.payload.data.attributes.data.length > 0) {
          state.eventsHaveRecords = "havedata";
        } else {
          state.eventsHaveRecords = "nodata";
        }
      }
    );
  },
});
export const policyActions = policySlice.actions;
export default policySlice.reducer;
