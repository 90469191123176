import * as React from "react";

const PassedPolicyIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width="16"
    height="16"
    fill="#2a6db5"
    stroke="#2a6db5"
    version="1.1"
    viewBox="0 0 24 24"
  >
    <g id="SVGRepo_iconCarrier">
      <path d="M11.7 2h-.8l.2 2h1.3c4 .3 7.3 3.5 7.5 7.6.2 4.4-3.2 8.2-7.6 8.4h-1.2l-.1 2h1.7c5.4-.4 9.5-5 9.3-10.4-.2-5.1-4.3-9.1-9.3-9.5h-.5c-.2-.1-.3-.1-.5-.1zm-3.5.7c-.5.3-1 .5-1.5.8l1.1 1.7c.3-.2.7-.4 1.1-.6zM4.5 5.4c-.4.4-.7.9-1 1.3l1.7 1c.2-.3.5-.6.8-1zm10.9 3-4.6 5.2-2.7-2.1L7 13.2l4.2 3.2L17 9.8zM2.4 9c-.2.5-.3 1.1-.3 1.6l2 .3c.1-.4.1-.9.3-1.3zm1.7 4-2 .2v.3c.1.4.2.9.3 1.3l1.9-.6c-.1-.3-.2-.7-.2-1.1zm1.1 3.2-1.7 1.1c.3.5.6.9 1 1.3L6 17.3c-.3-.4-.6-.7-.8-1.1zm2.6 2.6-1.1 1.7c.5.3 1 .5 1.5.8l.8-1.8c-.5-.3-.9-.5-1.2-.7z"></path>
    </g>
  </svg>
);

export default PassedPolicyIcon;
