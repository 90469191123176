import * as React from "react";
import { Tooltip } from "@mui/material";
const InternalValve = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width="30"
    height="30"
    fill="#000"
    version="1.1"
    viewBox="0 0 302.4 302.4"
  >
    <g id="SVGRepo_iconCarrier">
      <path d="M151.2 25.2c-39.949 0-72.45 32.501-72.45 72.45 0 13.957 3.993 27.509 11.547 39.188a72.95 72.95 0 0 0 29.838 26.224 6.297 6.297 0 0 0 9.014-5.635c.096-12.119 9.987-21.977 22.051-21.977 12.111 0 22.003 9.848 22.05 21.952a6.3 6.3 0 0 0 9.015 5.661 72.94 72.94 0 0 0 29.838-26.224c7.554-11.68 11.547-25.231 11.547-39.188 0-39.95-32.501-72.451-72.45-72.451m50.323 104.796a60.3 60.3 0 0 1-17.171 17.421c-4.333-14.197-17.574-24.567-33.152-24.567-15.529 0-28.768 10.379-33.13 24.583a60.3 60.3 0 0 1-17.193-17.437c-6.232-9.636-9.527-20.822-9.527-32.345 0-30.874 23.499-56.36 53.55-59.519V56.7h12.6V38.131c30.05 3.159 53.55 28.646 53.55 59.519 0 11.524-3.294 22.71-9.527 32.346"></path>
      <path d="M126 72.45h12.6v12.6H126zM144.9 72.45h12.6v12.6h-12.6zM163.8 72.45h12.6v12.6h-12.6zM113.4 94.5h63v12.6h-63zM151.2 144.9c-6.948 0-12.6 5.652-12.6 12.6s5.652 12.6 12.6 12.6 12.6-5.652 12.6-12.6-5.652-12.6-12.6-12.6"></path>
      <path d="M255.15 66.15h-11.54c-6.194-18.143-17.81-34.369-33.09-46.078C193.384 6.941 172.871 0 151.2 0c-21.672 0-42.184 6.941-59.318 20.072C76.589 31.791 64.969 48.034 58.794 66.15H47.25c-6.948 0-12.6 5.652-12.6 12.6v37.8c0 6.948 5.652 12.6 12.6 12.6h11.554a97.65 97.65 0 0 0 25.73 39.834 97.5 97.5 0 0 0 41.467 23.021v31.646a6.3 6.3 0 0 0 6.3 6.3h3.15v72.45h12.6v-72.45h6.3v72.45h12.6v-72.45h3.15a6.3 6.3 0 0 0 6.3-6.3v-31.644a97.5 97.5 0 0 0 41.467-23.022 97.7 97.7 0 0 0 25.737-39.834h11.546c6.948 0 12.6-5.652 12.6-12.6v-37.8c-.001-6.949-5.653-12.601-12.601-12.601m-199.754 50.4H47.25v-37.8h8.147a97 97 0 0 0-1.847 18.9c0 6.457.631 12.789 1.846 18.9M163.8 217.35h-25.2v-6.3h15.75v-12.6H138.6v-3.966c4.218.542 8.434.816 12.6.816s8.38-.273 12.6-.816zm69.165-96.348c-4.212 14.797-12.409 28.208-23.708 38.782-11.346 10.617-25.335 17.914-40.426 21.094-5.883 1.209-11.815 1.822-17.632 1.822-5.811 0-11.744-.613-17.602-1.815-15.122-3.187-29.111-10.483-40.455-21.1a85.05 85.05 0 0 1-23.718-38.838c-2.173-7.377-3.275-15.214-3.275-23.297s1.102-15.92 3.291-23.353c4.962-17.443 15.654-33.149 30.104-44.224C114.462 18.642 132.323 12.6 151.2 12.6s36.738 6.042 51.655 17.472C217.307 41.148 228 56.854 232.978 74.344c2.171 7.408 3.272 15.249 3.272 23.306s-1.101 15.898-3.285 23.352m22.185-4.452h-8.148a97 97 0 0 0 1.848-18.9c0-6.443-.631-12.773-1.847-18.9h8.147z"></path>
    </g>
  </svg>
);

export default InternalValve;
